import React, { ChangeEvent, FC, useMemo } from "react";
import { Form, Input } from "antd";
import { Rule } from "antd/es/form";

interface Props {
  label: string;
  name?: string;
  isEmail?: boolean;
  isURL?: boolean;
  isPassword?: boolean;
  isRequired?: boolean;
  onChange?: (name: string) => void;
  customInputProps?: any;
  hideLabel?: boolean;
}
const FormInput: FC<Props> = ({
  label,
  name,
  isEmail,
  isURL,
  isPassword,
  isRequired,
  onChange,
  customInputProps,
  hideLabel,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value);
  };

  const rules: Array<Rule> = useMemo(() => {
    const output = [];

    if (isEmail)
      output.push({
        type: "email" as any,
        message: "Please enter a valid email",
      });

    if (isURL)
      output.push({ type: "url", message: "Please enter a valid URL" });

    if (isRequired)
      output.push({ required: true, message: `${label} is required field` });

    return output;
  }, [isURL, isEmail, isRequired, label]);

  return (
    <Form.Item
      name={name || label.toLowerCase().replaceAll(" ", "-")}
      label={hideLabel ? "" : label}
      rules={rules}
    >
      {isPassword ? (
        <Input.Password {...customInputProps} />
      ) : (
        <Input onChange={handleChange} {...customInputProps} />
      )}
    </Form.Item>
  );
};

export default FormInput;
