import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p><strong>Effective Date:</strong> June 3, 2024</p>
      
      <h2>1. Information We Collect</h2>
      <h3>1.1 Information You Provide</h3>
      <p>
        When you use our Service, we may collect personal information that you voluntarily provide to us, such as:
      </p>
      <ul>
        <li>Contact information (e.g., name, email address, phone number).</li>
        <li>Account credentials (e.g., username, password).</li>
        <li>Profile information (e.g., job title, company name).</li>
      </ul>
      
      <h3>1.2 Information We Collect Automatically</h3>
      <p>
        We may also collect certain information automatically when you use the Service, including:
      </p>
      <ul>
        <li>Usage data (e.g., pages visited, features used).</li>
        <li>Device information (e.g., device type, operating system).</li>
        <li>Log data (e.g., IP address, access times).</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>
        We may use the information we collect for various purposes, including to:
      </p>
      <ul>
        <li>Provide and maintain the Service.</li>
        <li>Improve and personalize the Service.</li>
        <li>Communicate with you about your account and our services.</li>
        <li>Analyze usage trends and preferences.</li>
        <li>Detect, prevent, and address technical issues.</li>
      </ul>

      {/* Add more sections as needed */}
      
      <h2>3. Data Sharing and Disclosure</h2>
      <p>
        We may share your personal information with third parties in the following circumstances:
      </p>
      <ul>
        <li>With your consent.</li>
        <li>To comply with legal obligations.</li>
        <li>To protect the rights and property of ChronoFlow.</li>
        <li>In connection with a business transaction (e.g., merger, acquisition).</li>
      </ul>
      <h2>4. Data Security</h2>
      <p>
      We take the security of your personal information seriously and implement appropriate technical and organizational measures to protect it against unauthorized access, disclosure, alteration, or destruction.
      </p>
      <h2>5. Data Retention</h2>
      <p>
      We will retain your personal information only for as long as necessary to fulfill the purposes for which it was collected and to comply with legal obligations.
      </p>
      <h2>6. Your Rights</h2>
      <p>
      You have certain rights regarding your personal information, including the right to:
      <ul>
        <li>Access and update your information.</li>
        <li>Request deletion of your information.</li>
        <li>Object to the processing of your information.</li>
        <li>Opt-out of certain communications.</li>
      </ul>
    
      </p>
      <h2>7. Third-Party Links and Services</h2>
      <p>
      Our Service may contain links to third-party websites or services that are not owned or controlled by ChronoFlow. We are not responsible for the privacy practices or content of these third-party sites.
      </p>
      <h2>8. Children's Privacy</h2>
      <p>
      Our Service is not intended for use by children under the age of 18. We do not knowingly collect personal information from children under the age of 18 without parental consent.
      </p>
      <h2>9. Changes to this Privacy Policy</h2>
      <p>
      We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
      </p>
      <h2>10. Contact Us</h2>
      <p>
      If you have any questions or concerns about this Privacy Policy, please contact us at:

ammara@chronoflow.ai       </p>
      {/* Add remaining sections similarly */}
    </div>
  );
}

export default PrivacyPolicy;








 