import React, { FC } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface Props {
  value?: string;
  onChange?: (e: string) => void;
  placeholder?: string;
}

const EnrichEditor: FC<Props> = (props) => (
  <ReactQuill
    {...props}
    theme="snow"
    modules={{
      toolbar: [
        [{ header: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
      ],
    }}
    className="text-editor"
    placeholder={props.placeholder || "Insert Email body"}
  />
);

export default EnrichEditor;
