import { StripeElementsOptions } from "@stripe/stripe-js";

export interface IPlan {
  title: string;
  price: string | number;
  features: Array<string>;
  salePrice?: number;
  preFix?: string;
  postFix?: string;
  btn?: string;
}

export const plans: Array<IPlan> = [
  {
    title: "Free Trial",
    price: "7 days",
    features: [
      "Interview Scheduling with Hiring Manager",
      "Quick Coordination with Hiring Managers",
      "Notes Sharing",
      "Super Easy Dashboard Setup with Microsoft Signin",
      "Standard Support",
    ],
  },
  {
    title: "Premium",
    preFix: "$",
    salePrice: 30,
    price: 60,
    postFix: "  per person/month",
    features: [
      "Interview Scheduling with Hiring Manager",
      "Quick Coordination with Hiring Managers",
      "Notes Sharing",
      "Super Easy Dashboard Setup with Microsoft Signin",
      "Standard Support",
    ],
    btn: "Subscribe",
  },
  {
    title: "Enterprise",
    price: "Contact Us",
    features: [
      "Interview Scheduling with a team",
      "Team Collaboration on Dashboards",
      "Custom Features",
      "Custom Signin Options",
      "Priority Support",
      "Great for Small Companies",
    ],
    btn: "Contact Us",
  },
];

export const stripeCurrency = 'usd';