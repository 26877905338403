import moment from "moment";

export const AllMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export enum AvailabilityStatus {
  AVAILABLE = "available",
  RESERVED = "reserved",
  DISABLED = "disabled",
}

export interface IWorkingHours {
  startTime: number;
  endTime: number;
}

export const defaultWorkingHours: IWorkingHours = {
  startTime: 9,
  endTime: 17,
};

export enum DisplayMode {
  EVERYDAY = "Everyday",
  WORKINGDAY = "Working Days",
}

export const getDaysInMonth = (month: number) => {
  const currentYear = new Date().getFullYear();
  return new Date(currentYear, month + 1, 0).getDate();
};

export const getFormattedHours = (hour: number) => {
  return moment(hour, "HH:mm").format("LT");
};

export interface IAvailableSlot {
  date: string; // date-month
  time: Array<number>;
  reserved?: boolean;
  disabled?: boolean;
}

export interface IReservedSlot {
  date: string; // date-month
  time: number;
  title?: string;
}

export const getDatesArray = (month: number, mode: DisplayMode) => {
  const days = getDaysInMonth(month);
  const output = [];
  for (let i = 1; i <= days; i += 1) {
    const dateObj = moment(`${i}/${month + 1}`, "D/MM");
    const isWeekend = [0, 6].includes(dateObj.day());
    if (mode === DisplayMode.WORKINGDAY && isWeekend) continue;
    output.push(dateObj);
  }
  return output;
};

export const getHourBounds = (slots: Array<IAvailableSlot>): IWorkingHours => {
  const times = slots
    .map((e) => e.time)
    .flat(1)
    .sort();
  return { startTime: Math.min(...times), endTime: Math.max(...times) + 1 };
};

export const DateFormat = "MM-DD";

export interface IInterviewDate {
  date: string;
  hour: number;
}

export const getHourClassName = (
  status?: AvailabilityStatus,
  availabilityView?: boolean
): string => {
  if (status === AvailabilityStatus.RESERVED) return "reserved";

  const isAvailable = status === AvailabilityStatus.AVAILABLE;

  if (availabilityView) {
    if (isAvailable) return "";
    return "unavailable";
  }

  if (isAvailable) return "available";
  return "";
};

export const checkAvailabilityStatus = (
  availableSlots: Array<IAvailableSlot>,
  reservedSlots: Array<IReservedSlot>,
  dateString: string,
  time: number
): AvailabilityStatus => {
  const isReserved = reservedSlots.find(
    (e) => e.date === dateString && e.time === time
  );
  if (isReserved) {
    if (isReserved.title) return AvailabilityStatus.RESERVED;
    return AvailabilityStatus.DISABLED;
  }

  const availableSlot = availableSlots.find(
    (e) => e.date === dateString && e.time.includes(time)
  );
  if (!availableSlot) return AvailabilityStatus.DISABLED;

  return AvailabilityStatus.AVAILABLE;
};
