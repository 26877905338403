import { Spin } from "antd";
import React from "react";
import { useUser } from "../context";
import { UserType } from "./common";
import ManagersPanel from "./ManagersPanel";
import RecruitersPage from "./RecruitersPanel";

const DashboardPage = () => {
  const { user } = useUser();

  if (user?.category === UserType.MANAGER) return <ManagersPanel />;
  if (user?.category === UserType.RECRUITER) return <RecruitersPage />;
  return <Spin fullscreen />;
};

export default DashboardPage;
