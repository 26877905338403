import { Typography, Select } from "antd";
import React, { FC, useMemo, useState } from "react";
import { IStatus, getStatusList } from "../helper";
import { CheckOutlined } from "@ant-design/icons";
import { StatusType } from "../../common";
import { axiosInstant, endpoints } from "../../../api";

interface Props {
  id: string;
  status?: StatusType;
  onUpdate: () => void;
}

export const EditableStatus: FC<Props> = ({ id, status: initStatus, onUpdate }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [status, setStatus] = useState(
    getStatusList(initStatus ? [initStatus] : undefined)[0]
  );

  const handleChange = (val: IStatus) => {
    if (id)
      axiosInstant.patch(endpoints.getSingleCandidate(id), { status: val.value });
    setIsEditable(false);
    setStatus(val);
    onUpdate();
  };

  const makeStatusEditable = () => {
    setIsEditable(
      [StatusType.PENDING, StatusType.SCHEDULED].includes(
        status.value as StatusType
      )
    );
  };

  const statusOptions = useMemo(() => {
    if (status.value === StatusType.PENDING)
      return getStatusList([StatusType.SHORTLISTED, StatusType.REJECTED]);
    if (status.value === StatusType.SCHEDULED)
      return getStatusList([StatusType.APPROVED, StatusType.REJECTED]);
    return [];
  }, [status]);

  if (!isEditable)
    return (
      <Typography.Text onClick={makeStatusEditable}>
        {status?.label}
      </Typography.Text>
    );
  return (
    <Select
      style={{ width: "70%" }}
      optionLabelProp="label"
      placeholder="Select Selectus"
      menuItemSelectedIcon={<CheckOutlined />}
      value={status}
      labelInValue
      onChange={handleChange}
    >
      {statusOptions.map((status) => (
        <Select.Option key={status.value} {...status}>
          {status.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default EditableStatus;
