// Footer.tsx
import React from 'react';
import '../App.css'; // Import the CSS file for styling

const Footer: React.FC = () => {
  return (
    <footer className="footer">
       
      <div className="footer-bottom" style={{color: '#001529'}}>
        <p>&copy; 2024 ChronoFlow. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
