import { CSSProperties } from "react";

export const ProjectsMainWrapper: CSSProperties = {
  padding: "0 30px",
};

export const NoProjectsWrapper: CSSProperties = {
  height: 120,
  borderRadius: 6,
  border: "1px solid #888",
  padding: 120,
  borderStyle: "dashed",
};

export const priorityList = [
  { label: "Highest", value: 5 },
  { label: "High", value: 4 },
  { label: "Medium", value: 3 },
  { label: "Low", value: 2 },
  { label: "Lowest", value: 1 },
];
