import React, { FC } from "react";
import {
  AvailabilityStatus,
  IAvailableSlot,
  IReservedSlot,
  checkAvailabilityStatus,
  getFormattedHours,
  getHourClassName,
} from "./helper";
import moment, { Moment } from "moment";
import { Tooltip } from "antd";
import parse from "html-react-parser";

interface Props {
  datesArray: Moment[];
  availabilityView?: boolean;
  availableSlots: Array<IAvailableSlot>;
  reservedSlots: Array<IReservedSlot>;
  onClick: (date: string, hour: number) => void;
  hour: number;
}

const AvailabilityRow: FC<Props> = ({
  datesArray,
  availableSlots,
  reservedSlots,
  availabilityView,
  onClick,
  hour,
}) => {
  const getBoxText = (
    status: AvailabilityStatus,
    isReserved?: boolean,
    dateString?: string
  ) => {
    if (status === AvailabilityStatus.AVAILABLE) return getFormattedHours(hour);
    if (!isReserved) {
      if (availabilityView) return "";
      return getFormattedHours(hour);
    }

    const time = moment(hour, "HH").format("LT");
    const { title: titleText } =
      reservedSlots.find((e) => e.date === dateString && e.time === hour) || {};

    return (
      <div style={{ textAlign: "center" }}>
        <Tooltip
          title={parse(
            `Your meeting "<b>${titleText || ""}</b>" Scheduled at ${dateString}-2024 ${time}`
          )}
          color="#000"
        >
          Reserved
        </Tooltip>
      </div>
    );
  };

  return (
    <>
      {datesArray.map((date) => {
        const dateString = date.format("MM-DD");

        const status = checkAvailabilityStatus(
          availableSlots,
          reservedSlots,
          dateString,
          hour
        );
        const isReserved = status === AvailabilityStatus.RESERVED;

        return (
          <td
            key={`${date.toISOString()}-${hour}-box`}
            className={getHourClassName(status, availabilityView)}
            onClick={() => (isReserved ? null : onClick(dateString, hour))}
          >
            {getBoxText(status, isReserved, dateString)}
          </td>
        );
      })}
    </>
  );
};

export default AvailabilityRow;
