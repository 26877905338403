import React, { FC } from "react";
import PhoneInput from "antd-phone-input";
import { Form } from "antd";

interface Props {
  label: string;
  name?: string;
  isRequired?: boolean;
}

const FormPhoneInput: FC<Props> = ({ label, name, isRequired }) => {
  const validator = (_: any, { valid }: any) => {
    if (valid()) return Promise.resolve(); // non-strict validation
    return Promise.reject("Invalid phone number");
  };

  return (
    <Form.Item
      name={name || label.replaceAll(" ", "-")}
      label={label}
      rules={isRequired ? [{ validator }] : []}
    >
      <PhoneInput />
    </Form.Item>
  );
};

export default FormPhoneInput;
