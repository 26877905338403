// src/Components/FAQ.js
import React from 'react';
import { Collapse } from 'antd';
import { Typography } from 'antd';
import '../App.css';

const { Panel } = Collapse;
const { Title } = Typography;

const FAQ = () => {
  return (
    <div id="faq" className="faq-section">
      <Title level={2} className="faq-title">Frequently Asked Questions</Title>
      <Collapse accordion>
      <Panel header="How are Calendars shared between Recruiters and Hiring Managers?" key="1">
          <p>Hiring Managers have more control over Calendars since they declare their availability to recruiters. Their calendars are merged with ChronoFlow calendar. But the recruiters can only see their available slots thus keeping Hiring Manager's privacy intact. Recruiters can only see and select available slots for interviews in their ChronoFlow Calendars and can schedule interviews right away.</p>
        </Panel>
        <Panel header="How does Chronoflow integrate with ATS and video calling apps?" key="2">
          <p>ChronoFlow uses advanced AI algorithms to integrate effortlessly with various ATS and video calling platforms. This integration allows recruiters to manage their schedules, coordinate interviews, and communicate with candidates all from a single platform.</p>
        </Panel>
        <Panel header="What are the benefits of using Chronoflow?" key="3">
          <p>ChronoFlow offers several benefits, including improved scheduling efficiency, reduced manual workload, seamless integration with existing tools, and enhanced recruiter's experience. Its AI-driven features help recruiters save time and focus on finding the best talent.</p>
        </Panel>
        <Panel header="Is Chronoflow easy to set up and use?" key="4">
          <p>Yes, ChronoFlow is designed to be user-friendly and easy to set up. The integration process is straightforward, and our support team is always available to assist with any setup or usage questions you may have.</p>
        </Panel>
        <Panel header="Can Chronoflow handle scheduling for multiple recruiters?" key="5">
          <p>Absolutely! ChronoFlow is built to support teams of any size. It allows multiple recruiters to manage interview schedules, coordinate with Hiring Managers, and collaborate seamlessly within the platform.</p>
        </Panel>
      </Collapse>
    </div>
  );
};

export default FAQ;
