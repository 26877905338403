import React, { useEffect } from "react";
import Navbar from "./NavBar";
import HeroSection from "./Herosection";
import { Layout } from "antd";
import FAQ from "./FAQ";
import Footer from "./Footer";
import PricingCards from "./Payment";
import Features from "./Features";
import loadGAScript from "./loadGAScript";
import VideoSection from "./VideoComponent";


const { Content } = Layout;

const LandingPage = () => {
  useEffect(() => {
    loadGAScript(process.env.REACT_APP_GOOGLE_TRACKING_ID);
  }, []);

  return (
    <div>
      <div className="landing-page-content">
        <Layout className="layout">
          <Navbar />
          <Content style={{ padding: "0 50px" }}>
            <HeroSection />
          </Content>
        </Layout>
      </div>
      <VideoSection videoId="aVmh1rvaJIY"/>
      <Features />
      <PricingCards />
      <FAQ />
      <Footer />
    </div>
  );
};

export default LandingPage;
