import { Form, Modal, Space, Typography } from "antd";
import React, { FC, useRef } from "react";
import FormInput from "../../FormInput";
import { axiosInstant, endpoints } from "../../../api";
import ResumeUpload from "./resumeUpload";
import { showError, showSuccess } from "../../../Notifications";
import TextArea from "antd/es/input/TextArea";

interface Props {
  projectId?: string;
  onOkay: () => void;
  onCancel: () => void;
}

export const CreateNewApplicant: FC<Props> = (props) => {
  const formRef = useRef<any>(null);
  const { onOkay, projectId } = props;
  const [form] = Form.useForm();

  const clickToSubmit = () => {
    formRef.current?.submit();
  };

  const handleSubmit = async () => {
    if (!projectId) onOkay();

    try {
      const values = await form.validateFields();
      const { data } = await axiosInstant.post(endpoints.candidate, {
        ...values,
        projectId,
      });

      if (values.remark && data._id) {
        await axiosInstant.post(endpoints.createRemark, {
          text: values.remark,
          candidateId: data._id,
        });
      }

      showSuccess("Applicant created successfully");
      onOkay();
    } catch (error) {
      showError((error as Error)?.message);
      onOkay();
    }
  };

  return (
    <Modal
      {...props}
      title="Create New Applicant"
      centered
      open
      onOk={clickToSubmit}
    >
      <Form
        form={form}
        style={{ marginTop: 20 }}
        clearOnDestroy
        validateTrigger={["onChange", "onSubmit"]}
        preserve={false}
        ref={formRef}
        onFinish={handleSubmit}
      >
        <FormInput label="Email" name="email" isRequired isEmail />
        <FormInput label="Full Name" name="name" isRequired />
        <FormInput label="Position / Role" name="position" isRequired />
        <Form.Item name="resume" label="Resume">
          <Space direction="vertical" style={{ width: "100%", gap: 20 }}>
            <ResumeUpload
              onUpload={(url: string) => form.setFieldValue("resume", url)}
            />
            <Typography style={{ fontStyle: "italic" }}>--OR--</Typography>
            <FormInput
              label=""
              name="resume"
              isURL
              customInputProps={{
                placeholder: "Insert Resume URL",
                style: { width: 300 },
              }}
            />
          </Space>
        </Form.Item>
        <Form.Item label="Remarks (if any)" name="remark">
          <TextArea rows={3} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateNewApplicant;
