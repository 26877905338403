import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  AuthIgnoredPaths,
  ROUTES,
  Subscription,
  TOKEN_KEY,
  UserType,
} from "../Components/common";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosInstant, endpoints } from "../api";
import { Spin } from "antd";
import AuthFormModal from "../Components/AuthPanel/AuthFormModal";
import moment from "moment";

interface ISubscription {
  name: Subscription;
  expiredAt: number;
  subscribedAt: number;
}
interface IUser {
  category: UserType;
  email: string;
  isActive: string;
  name: string;
  token: string;
  _id: string;
  subscription?: null | ISubscription;
}

const initValues: IUser = {
  category: UserType.MANAGER,
  email: "",
  isActive: "false",
  name: "",
  token: "",
  _id: "",
};

interface IUserContext {
  user: IUser;
  setUser: (e?: IUser) => void;
  getMe: () => Promise<IUser | undefined>;
}

const AuthContext = createContext<IUserContext>({
  user: initValues,
  setUser: () => {},
  getMe: () => Promise.resolve(undefined),
});

export const useUser = () => useContext(AuthContext);

interface Props {
  children: ReactNode;
}
export const AuthProvider: FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);
  const [showLoginForm, updateLoginFormView] = useState(false);
  const [user, setUser] = useState<IUser>(initValues);

  const updateUserValue = (e?: IUser) => setUser(e || initValues);
  const handleLoginErr = (isManual?: boolean) => {
    if (isManual) updateLoginFormView(true);
    else navigate(ROUTES.LANDING);
  };

  const verifyUserSubscription = async (user: IUser) => {
    const { expiredAt = 0 } = user.subscription ||  {};
    if (expiredAt < moment().valueOf()) navigate(ROUTES.SUBSCRIBE);
  };

  const getMe = async (isManual?: boolean) => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      setLoading(false);
      handleLoginErr(isManual);
      return;
    }

    try {
      const { data } = await axiosInstant.get(endpoints.getMe);
      if (!data?.token) handleLoginErr(isManual);
      else {
        setUser(data);
        localStorage.setItem(TOKEN_KEY, data.token);
        verifyUserSubscription(data);
      }
      setLoading(false);
      return data;
    } catch (err: any) {
      if (err?.response?.status === 404) {
        setUser(initValues);
        localStorage.clear();
        navigate(ROUTES.LANDING); // Redirect to login page
      }
      handleLoginErr(isManual);
      return;
    }
  };

  useEffect(() => {
    if (!AuthIgnoredPaths.includes(pathname as ROUTES) && !user.token) getMe();
    else setLoading(false);
  }, [user, pathname]);

  if (loading) return <Spin spinning fullscreen percent="auto" />;
  return (
    <AuthContext.Provider value={{ user, setUser: updateUserValue, getMe }}>
      {children}
      {showLoginForm && (
        <AuthFormModal onCancel={() => updateLoginFormView(false)} />
      )}
    </AuthContext.Provider>
  );
};
