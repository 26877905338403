import React, { FC } from "react";
import { IOnPdfUpload } from "../helper";
import { FilePdfOutlined } from "@ant-design/icons";
import ResumeUpload from "./resumeUpload";

interface Props {
  _id: string;
  resume?: string;
  onUpload: IOnPdfUpload;
}

export const ApplicantResume: FC<Props> = ({ _id, resume, onUpload }) => {
  if (resume)
    return (
      <a href={resume} target="_blank" rel="noreferrer">
        <FilePdfOutlined style={{ fontSize: 20, color: "#002140" }} />
      </a>
    );

  return <ResumeUpload isNarrowed onUpload={(url) => onUpload(_id, url)} />;
};

export default ApplicantResume;
