import React, { FC } from "react";
import { DisplayMode } from "./helper";

interface Props {
  displayMode: DisplayMode;
  setDisplayMode: (mode: DisplayMode) => void;
}

const DisplayModeSelector: FC<Props> = ({ displayMode, setDisplayMode }) => (
  <div className="display-mode-selector">
    <label>Display Mode: </label>
    <select
      value={displayMode}
      onChange={(e) => setDisplayMode(e.target.value as DisplayMode)}
    >
      {Object.entries(DisplayMode).map(([key, value]) => (
        <option value={value} key={key}>{value}</option>
      ))}
    </select>
  </div>
);

export default DisplayModeSelector;
