import React, { useState } from "react";
import { SignUpTypes } from "./helper";
import AuthMainPanel from "./DefaultMainPanel";
import ManualSignUpPanel from "./ManualSignUpPanel";
import { Modal } from "antd";

const AuthPanel = () => {
  const [signUpType, setSignUpType] = useState<SignUpTypes>();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleSelect = (type: SignUpTypes) => {
    setSignUpType(type);
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setSignUpType(undefined);
    setIsModalVisible(false);
  };

  return (
    <>
      <AuthMainPanel onSelect={handleSelect} />
      <Modal
        title=""
        visible={isModalVisible}
        onCancel={handleClose}
        footer={null}
        centered
      >
        {signUpType === SignUpTypes.MANUAL && (
          <ManualSignUpPanel onClose={handleClose} visible={isModalVisible} />
        )}
      </Modal>
    </>
  );
};

export default AuthPanel;
