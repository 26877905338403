import { Row, Col, Button, Flex } from "antd";
import React, { FC, useEffect, useState } from "react";
import { IApplicant, IProjectDetails } from "../helper";
import ApplicantsList from "./applicantsList";
import UploadApplicants from "./uploadApplicants";
import { axiosInstant, endpoints } from "../../../api";
import { CreateNewApplicant } from "./createApplicantModal";
import { showError } from "../../../Notifications";
import RemarksPanel from "../../../Components/RemarksPanel";

interface Props {
  project?: IProjectDetails;
}

export const ApplicantsListPanel: FC<Props> = ({ project }) => {
  const [applicants, setApplicants] = useState<Array<IApplicant>>([]);
  const [selectedCandidate, setSelectedCandidate] = useState("");
  const [openCandidateModal, setCandidateModalVisibility] = useState(false);
  const showCandidateModal = () => setCandidateModalVisibility(true);
  const hideCandidateModal = () => setCandidateModalVisibility(false);

  const createApplicant = () => {
    loadApplicantsList();
    hideCandidateModal();
  };

  const updateApplicantsArray = (newApplicants: Array<IApplicant>) => {
    const freshApplicants = newApplicants.filter(
      (applicant) => !applicants.find((e) => e._id === applicant._id)
    );
    setApplicants((prev) => [...prev, ...freshApplicants]);
  };

  const onDelete = async (_id: string) => {
    try {
      await axiosInstant.delete(endpoints.getSingleCandidate(_id));
      const results = applicants.filter((e) => e._id !== _id);
      setApplicants(results);
    } catch (e) {
      showError(e);
    }
  };

  const onPdfUpload = async (_id: string, resume: string) => {
    const { data } = await axiosInstant.patch(endpoints.getSingleCandidate(_id), {
      resume,
    });
    const tempApplicants = [...applicants];
    const index = tempApplicants.findIndex((e) => e._id === _id);
    if (index > -1) tempApplicants[index] = data;
    setApplicants(tempApplicants);
  };

  const loadApplicantsList = () => {
    if (project?._id)
      axiosInstant
        .get(endpoints.getAllCandidates(project._id))
        .then((response) => {
          const { data } = response;
          if (data) setApplicants(data);
        })
        .catch((error) => showError(error));
  };

  const sendEmailRequest = async (_id: string, emailBody: string) => {
    try {
      await axiosInstant.post(endpoints.emailRequest, {
        projectId: project?._id,
        candidateId: _id,
        emailBody,
      });
      loadApplicantsList();
    } catch (e) {
      showError(e);
    }
  };

  useEffect(loadApplicantsList, [project]);

  const props = {
    applicants,
    project,
    projectId: project?._id,
    onDelete,
    onPdfUpload,
    openRemarks: setSelectedCandidate,
    sendEmailRequest,
  };
  return (
    <>
      <Row>
        <Col span={24} style={{ padding: "10px 20px" }}>
          <Flex justify="end" gap={20}>
            <UploadApplicants {...props} onUpload={updateApplicantsArray} />
            <Button size="large" type="primary" onClick={showCandidateModal}>
              Add New Applicant
            </Button>
          </Flex>
        </Col>
        <Col span={24}>
          <ApplicantsList {...props} />
        </Col>
      </Row>

      {openCandidateModal && (
        <CreateNewApplicant
          projectId={project?._id}
          onOkay={createApplicant}
          onCancel={hideCandidateModal}
        />
      )}

      <RemarksPanel
        candidateId={selectedCandidate}
        onCancel={() => setSelectedCandidate("")}
      />
    </>
  );
};
export default ApplicantsListPanel;
