import React, { useState, useMemo, useEffect } from "react";
import MonthDropdown from "./MonthDropdown";
import "../../App.css";
import {
  DateFormat,
  DisplayMode,
  IAvailableSlot,
  IInterviewDate,
  IReservedSlot,
  defaultWorkingHours,
  getDatesArray,
  getHourBounds,
} from "./helper";
import AvailabilityTable from "./AvailablilityTable";
import { axiosInstant, endpoints } from "../../api";
import { useSearchParams } from "react-router-dom";
import { Col, Row, Space, Typography } from "antd";
import { IApplicant } from "../ManagersPanel/helper";
import ScheduleMeetingModal from "./ScheduleMeetingModal";
import { showError } from "../../Notifications";
import BackBtn from "../backBtn";

const AvailabilityCalendar = () => {
  const [searchParams] = useSearchParams();

  const [candidate, setCandidate] = useState<IApplicant>();
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedHours, setSelectedHours] = useState(defaultWorkingHours);
  const [defaultSlots, setDefaultSlots] = useState<Array<IAvailableSlot>>([]);
  const [reservedSlots, setReservedSlots] = useState<Array<IReservedSlot>>([]);
  const [loading, setLoading] = useState(true);
  const [interviewDate, setInterviewDate] = useState<IInterviewDate>();

  const datesArray = useMemo(() => {
    return getDatesArray(selectedMonth, DisplayMode.EVERYDAY).filter((e) => {
      const dateString = e.format(DateFormat);
      return defaultSlots.find((e) => e.date === dateString);
    });
  }, [defaultSlots, selectedMonth]);

  const loadCalendar = async (projectId: string) => {
    try {
      const { data } = await axiosInstant.get(
        endpoints.availabilityByProjectId(projectId, selectedMonth)
      );
      const { availableSlots = [], reservedSlots = [] } = data || [];
      setDefaultSlots(availableSlots);
      setReservedSlots(reservedSlots);
      setSelectedHours(getHourBounds(availableSlots));
    } catch (err) {
      showError(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    const projectId = searchParams.get("projectId");
    if (projectId && !interviewDate) loadCalendar(projectId);
  }, [searchParams, interviewDate, selectedMonth]);

  useEffect(() => {
    const applicantId = searchParams.get("applicantId");
    if (applicantId) {
      axiosInstant(endpoints.getSingleCandidate(applicantId))
        .then((res) => {
          if (res.data) setCandidate(res.data);
        })
        .catch((err) => console.log(err.mesasge));
    }
  }, [searchParams]);

  const centeredText = (text: string) => (
    <Row>
      <Col span={24}>
        <Typography.Title level={3} style={{ textAlign: "center" }}>
          {text}
        </Typography.Title>
      </Col>
    </Row>
  );

  return (
    <div className="calendar-container">
      <BackBtn />
      <Typography.Title level={2}>
        Schedule Interview for {candidate?.name}
      </Typography.Title>
      <MonthDropdown {...{ selectedMonth, setSelectedMonth }} />
      {!defaultSlots.length ? (
        centeredText(loading ? "Loading..." : "No available slots")
      ) : (
        <AvailabilityTable
          availabilityView
          {...{
            datesArray,
            selectedHours,
            reservedSlots,
            defaultSlots,
            onSelect: (date: string, hour: number) =>
              setInterviewDate({ date, hour }),
          }}
        />
      )}

      {candidate && interviewDate && (
        <ScheduleMeetingModal
          {...{ interviewDate, setInterviewDate, candidate }}
        />
      )}
    </div>
  );
};

export default AvailabilityCalendar;
