import React from "react";
import { Row, Col, Divider } from "antd";
import "../App.css"; // Assuming you have some additional styles here

const Features = () => {
  return (
    <div>
      <Divider className="section-divider" />
      <div className="image-text-section">
        <div className="container bordered-container">
          <Row gutter={[16, 16]} align="middle" className="content-reverse-row">
            <Col xs={24} md={14}>
              <div className="image-container">
                <img
                  src="/RECRUITER DASHBOARD.png"
                  alt="Description"
                  className="section-image"
                />
              </div>
            </Col>
            <Col xs={24} md={10}>
              <h2 className="section-title" style={{ color: "purple" }}>
                Track Shortlisted Candidates
              </h2>
              <p className="section-description">
                Live Coordination between Recruiter and Hiring Manager for each
                shortlisted candidate for Hiring.
              </p>
            </Col>
          </Row>
        </div>
      </div>
      <Divider className="section-divider" />
      <div className="image-text-section">
        <div className="container bordered-container">
          <Row gutter={[16, 16]} align="middle" className="content-row">
            <Col xs={24} md={10} order={1}>
              <h2 className="section-title" style={{ color: "purple" }}>Hassle-Free Interview Scheduling</h2>
              <p
                className="section-description-2"
                style={{ textAlighn: "left" }}
              >
                <ul>
                  <li>ChronoFlow integrates seamlessly with the Hiring Manager'sOutlook Calendar</li>
                  <li>Recruiter use available slots to schedule interviews</li>
                  <li>Eliminates Hiring Manager and Recruiter Coordination Delays</li>
                </ul>
              </p>
            </Col>
            <Col xs={24} md={14} order={2}>
              <div className="image-container">
                <img
                  src="/Calendar view.png"
                  alt="Description"
                  className="section-image"
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Divider className="section-divider" />
    </div>
  );
};

export default Features;
