import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Modal } from "antd";
import { FC, useEffect, useRef, useState } from "react";
import { stripeCurrency } from "./constants";
import CheckoutForm from "./CheckoutForm";
import { axiosInstant } from "../../api";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");

interface Props {
  open: boolean;
  amount: number;
  onCancel: () => void;
}
const PaymentForm: FC<Props> = ({ open, onCancel, amount }) => {
  const submitBtnRef = useRef<HTMLFormElement>(null);
  const [clientSecret, setClientSecret] = useState("");

  const submitForm = () => submitBtnRef.current?.click();

  useEffect(() => {
    if (amount > 0 && open) {
      axiosInstant
        .post("/subscription/paymentIntent", {
          currency: stripeCurrency,
          amount: amount * 100,
        })
        .then((res) => {
          const secret = res?.data?.client_secret;
          setClientSecret(secret);
        });
    }
  }, [amount, open]);

  const modalProps = { open, onCancel };
  return (
    <Modal
      {...modalProps}
      onOk={submitForm}
      title={`Pay $${amount} to Subscribe`}
    >
      {clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm submitBtnRef={submitBtnRef} />
        </Elements>
      )}
    </Modal>
  );
};

export default PaymentForm;
