import { Row, Col, Button, Flex } from "antd";
import React, { FC } from "react";
import { NoProjectsWrapper } from "../helper";

interface Props {
  onClick: () => void;
}
export const NoProjectsPage: FC<Props> = (props) => {
  return (
    <Row>
      <Col span={24}>
        <Flex style={NoProjectsWrapper} align="center" justify="center">
          <Button size="large" type="primary" {...props}>Create New Project</Button>
        </Flex>
      </Col>
    </Row>
  );
};

export default NoProjectsPage;
