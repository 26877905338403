import React, { useState, FC, useMemo } from "react";
import { IAvailableSlot, IReservedSlot, IWorkingHours } from "./helper";
import { Moment } from "moment";
import UpdateButton from "./UpdateButton";
import AvailabilityRow from "./AvailablilityRow";

interface Props {
  datesArray: Moment[];
  availabilityView?: boolean;
  selectedHours: IWorkingHours;
  defaultSlots: Array<IAvailableSlot>;
  reservedSlots: Array<IReservedSlot>;
  onUpdate?: (slots: Array<IAvailableSlot>) => void;
  onSelect?: (date: string, hour: number) => void;
}
const AvailabilityTable: FC<Props> = (props) => {
  const {
    selectedHours: { startTime, endTime },
    datesArray,
    defaultSlots,
    onUpdate,
    onSelect,
  } = props;

  const [availableSlots, setAvailableSlots] =
    useState<Array<IAvailableSlot>>(defaultSlots);

  const updateAvailabilitySlots = (date: string, hour: number) => {
    const slots = [...availableSlots];
    const existingDate = slots.find((e) => e.date === date);
    if (existingDate) {
      const hourIndex = existingDate.time.findIndex((e) => e === hour);
      if (hourIndex === -1) existingDate.time.push(hour);
      else {
        existingDate.time.splice(hourIndex, 1);
        if (!existingDate.time.length) {
          const dateIndex = slots.findIndex((e) => e.date === date);
          slots.splice(dateIndex, 1);
        }
      }
    } else {
      slots.push({ date, time: [hour] });
    }
    setAvailableSlots(slots);
  };

  const clickCallback = useMemo(
    () => onSelect ?? updateAvailabilitySlots,
    [onSelect, availableSlots]
  );

  return (
    <>
      <div style={{ width: "100%", overflow: "auto" }}>
        <table className="calendar-table" style={{ width: "100%" }}>
          <thead>
            <tr>
              {/* <th>Time/Dates</th> */}
              {datesArray.map((date) => (
                <th
                  key={`${date.toISOString()}-date-head`}
                  style={{ minWidth: "100px" }}
                >
                  <div>{date.format("MMM DD")}</div>
                  <div style={{ marginTop: 5 }}>{date.format("ddd")}</div>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {Array.from({ length: endTime - startTime }).map((_, i) => {
              return (
                <tr key={`table-${i}-row`}>
                  <AvailabilityRow
                    {...props}
                    availableSlots={availableSlots}
                    onClick={clickCallback}
                    hour={startTime + i}
                  />
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {onUpdate && <UpdateButton onClick={() => onUpdate(availableSlots)} />}
    </>
  );
};

export default AvailabilityTable;
