import React, { FC, RefObject, useEffect } from "react";
import { IInterviewDate } from "./helper";
import { Form, FormInstance, FormProps, Select } from "antd";
import { axiosInstant, endpoints } from "../../api";
import { IApplicant, IManager } from "../ManagersPanel/helper";
import FormInput from "../FormInput";
import { showError, showSuccess } from "../../Notifications";
import EnrichEditor from "../Editor";
import { emailEditorRules, getSchduleMeetingBody } from "../common/emailBody";
import { MeetingType } from "../common";

interface Props {
  loading: boolean;
  candidate: IApplicant;
  selectedManager?: IManager;
  interviewDate: IInterviewDate;
  setLoading: (e: boolean) => void;
  formRef: RefObject<FormInstance<any>>;
  setInterviewDate: (e?: IInterviewDate) => void;
}

const ScheduleMeetingForm: FC<Props> = ({
  loading,
  formRef,
  candidate,
  setLoading,
  selectedManager,
  setInterviewDate,
  interviewDate: { date, hour },
}) => {
  const [form] = Form.useForm();
  const closeModal = () => setInterviewDate(undefined);

  const handleSubmit: FormProps<{
    title: string;
    emailBody: string;
  }>["onFinish"] = async (values) => {
    if (loading) return;
    if (!selectedManager?._id) closeModal();

    const payload = {
      ...values,
      candidate: candidate._id,
      manager: selectedManager?._id,
      date,
      time: hour,
    };
    setLoading(true);
    try {
      await axiosInstant.post(endpoints.scehduleIntervew, payload);
      setTimeout(() => {
        closeModal();
        showSuccess("Meeting reserved successfully");
        setLoading(false);
      }, 8000);
    } catch (err) {
      showError(err);
      closeModal();
    }
  };

  useEffect(() => {
    if (date && hour && candidate && selectedManager) {
      const text = getSchduleMeetingBody(
        date,
        hour,
        selectedManager.name,
        candidate.position
      );
      form.setFieldValue("emailBody", text);
      form.setFieldValue("interviewType", MeetingType.ONLINE);
    }
  }, [selectedManager, date, hour, candidate, form]);

  return (
    <Form
      onFinish={handleSubmit}
      ref={formRef}
      style={{ marginTop: 20 }}
      form={form}
    >
      <div style={{ marginTop: 20 }}>
        <Form.Item
          label="Meeting Type"
          name="interviewType"
          rules={[
            { required: true, message: `Interview type is required field` },
          ]}
        >
          <Select>
            {Object.values(MeetingType).map((type) => (
              <Select.Option key={type} value={type}>
                {type}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <FormInput label="Meeting Title" name="title" isRequired />
        <Form.Item
          label="Email Body"
          name="emailBody"
          rules={emailEditorRules}
          layout="vertical"
          style={{ height: 270 }}
        >
          <EnrichEditor placeholder="Type email on behalf of Hiring Manager" />
        </Form.Item>
      </div>
    </Form>
  );
};

export default ScheduleMeetingForm;
