import { notification as notify } from "antd";

notify.config({
  top: 0,
  duration: 3,
  placement: "topRight",
});

interface INotification {
  message: string;
  description?: string;
}

const notification = {
  info: (notification: INotification) => notify.info(notification),
  success: (notification: INotification) => notify.success(notification),
  warning: (notification: INotification) => notify.warning(notification),
  error: (notification: INotification) => notify.error(notification),
};

export const showNotification = (
  type: "info" | "success" | "warning" | "error",
  message: string,
  description?: string
) => notification[type]({ message, description });

export const showError = (error: any, message = "Request Failed") => {
  const description =
    error?.response?.data?.message || new Error(error)?.message || "";
  notification.error({ message, description });
};

export const showSuccess = (description: string, message = "Success") => {
  notification.success({ message, description });
};
