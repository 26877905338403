import { Row, Col, Flex } from "antd";
import React, { useEffect, useState } from "react";
import { ProjectsMainWrapper, IProjectDetails } from "./helper";
import { ApplicantsListPanel } from "./applicantsPanel";
import { useNavigate, useSearchParams } from "react-router-dom";
import PageHeading from "./pageHeading";
import { axiosInstant, endpoints } from "../../api";
import ProjectList from "./projectsPanel/projectList";
import { showError } from "../../Notifications";
import BackBtn from "../backBtn";

export const ManagersPanel = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [projectList, setProjectList] = useState<Array<IProjectDetails>>([]);
  const [selectedProject, setSelectedProject] = useState<IProjectDetails>();

  useEffect(() => {
    axiosInstant
      .get(endpoints.myProjects)
      .then((e) => setProjectList(e.data))
      .catch((err) => showError(err));
  }, [navigate]);

  useEffect(() => {
    if (!projectList.length) return;
    const projectParam = searchParams.get("project") || -1;
    const project = projectList.find((e) => e._id === projectParam);
    if (project) setSelectedProject(project);
    else {
      setSelectedProject(undefined);
      setSearchParams((params) => {
        params.delete("project");
        return params;
      });
    }
  }, [projectList, searchParams, setSearchParams]);

  return (
    <Row>
      <Col span={24}>
        <Flex vertical style={ProjectsMainWrapper}>
          {selectedProject && <BackBtn />}
          <PageHeading project={selectedProject} />
          {selectedProject ? (
            <ApplicantsListPanel project={selectedProject} />
          ) : (
            <ProjectList list={projectList} />
          )}
        </Flex>
      </Col>
    </Row>
  );
};

export default ManagersPanel;
