import { Typography, Button } from "antd";
import React, { FC } from "react";
import { IScheduleCall } from "../helper";
import { StatusType } from "../../common";
import { startCase } from "lodash";

interface Props {
  _id: string;
  status?: string;
  scheduleCall: IScheduleCall;
}

export const ApplicantStatus: FC<Props> = ({ _id, status, scheduleCall }) => {
  if (status === StatusType.PENDING)
    return (
      <Typography style={{ fontStyle: "italic", color: "#727272" }}>
        Waiting for approval...
      </Typography>
    );
  if (status === StatusType.SHORTLISTED)
    return (
      <Button type="default" onClick={() => scheduleCall(_id)}>
        Schedule
      </Button>
    );
  return <Typography>{startCase(status)}</Typography>;
};

export default ApplicantStatus;
