import { Button } from "antd";
import React, { FC, useRef } from "react";
import { IApplicant, IUploadApplicant } from "../helper";
import { axiosInstant, endpoints } from "../../../api";
import { showError, showSuccess } from "../../../Notifications";
import * as xlsx from "xlsx";
import { transform } from "lodash";

interface Props {
  projectId?: string;
  onUpload: (data: Array<IApplicant>) => void;
}

export const UploadApplicants: FC<Props> = ({ projectId, onUpload }) => {
  const uploadRef = useRef<HTMLInputElement>(null);

  const isSpreadsheet = (type: string) =>
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ===
    type;

  const uploadData = async (dataArray: Array<IUploadApplicant>) => {
    if (!projectId) return;

    try {
      const payload = { projectId, candidates: dataArray };
      const response = await axiosInstant.post(
        endpoints.uploadCandidates,
        payload
      );
      const { data } = response;
      showSuccess("Applicants file is uploaded successfully");
      if (data) onUpload(data);
    } catch (error) {
      showError(error);
    }
  };

  const uploadFile = (e: any) => {
    const { files } = e.target;
    const uploadedFile = files[0];

    if (uploadedFile) {
      const reader = new FileReader();

      if (isSpreadsheet(uploadedFile.type)) {
        reader.onload = (event) => {
          const data = event.target?.result;
          const workbook = xlsx.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_json(worksheet);
          const output = json.map((e: any) =>
            transform(e, (result: any, val: IUploadApplicant, key: string) => {
              result[key.toLowerCase()] = val;
            })
          );
          e.target.value = "";
          uploadData(output);
        };
        reader.readAsArrayBuffer(uploadedFile);
      } else {
        reader.onload = () => {
          const dataArray = (reader.result as string)
            ?.split("\r\n")
            .map((e) => e.split(","));
          if (dataArray.length > 1) {
            const keys = dataArray[0].map((e) => e.toLowerCase());
            const output = [];
            for (let i = 1; i < dataArray.length; i += 1) {
              const values = dataArray[i];
              const obj = {} as any;
              keys.forEach(
                (key, index) => (obj[key.toLowerCase()] = values[index])
              );
              output.push({ ...obj });
            }
            uploadData(output);
          }
          e.target.value = "";
        };
        reader.readAsBinaryString(uploadedFile);
      }
    }
  };

  const uploadApplicants = () => uploadRef.current?.click();

  return (
    <>
      <Button size="large" type="default" onClick={uploadApplicants}>
        Upload File
      </Button>
      <input
        ref={uploadRef}
        type="file"
        onChange={uploadFile}
        accept=".csv, .xlsx"
        style={{ display: "none" }}
      />
    </>
  );
};
export default UploadApplicants;
