import { Table } from "antd";
import React, { FC, useMemo } from "react";
import { IProjectDetails, ProjectsColumns } from "../helper";
import { useSearchParams } from "react-router-dom";

interface Props {
  list: Array<IProjectDetails>;
}

export const ProjectList: FC<Props> = ({ list }) => {
  const [, setSearchParams] = useSearchParams();
  const handleChange = (id?: string) => {
    if (id)
      setSearchParams((params) => {
        params.set("project", id);
        return params;
      });
  };

  const dataSource = useMemo(
    () => list.map((e) => ({ ...e, key: e._id })),
    [list]
  );

  return (
    <Table dataSource={dataSource} columns={ProjectsColumns(handleChange)} />
  );
};

export default ProjectList;
