export enum UserType {
  RECRUITER = "recruiter",
  MANAGER = "manager",
}

export enum StatusType {
  PENDING = "pending",
  SHORTLISTED = "shortlisted",
  SCHEDULED = "scheduled",
  APPROVED = "approved",
  REJECTED = "rejected",
}

export enum Subscription {
  FREE = "free",
  STANDERD = "standerd",
  ENTERPRISE = "enterprise",
  SPECIAL = "SPECIAL",
}

export enum MeetingType {
  ONLINE = "Online (via Microsoft teams)",
  PHONE = "Phone",
  INPERSON = "In Person",
}

export interface Data {
  message: string;
}
export interface ErrorType {
  message: string;
  name: string;
  stack: string;
  code: string;
  status: number;
  response: {
    data: {
      message: string;
    };
    status: number;
    statusText: string;
  };
}

export const TOKEN_KEY = "ACCESS_TOKEN";

export enum ROUTES {
  LANDING = "/",
  FINISH_SIGNUP = "/finishSignup",
  AVAILABILITY = "/availability",
  TERMS = "/terms",
  POLICY = "/policy",
  CALENDAR = "/calendar",
  DASHBOARD = "/dashboard",
  SUBSCRIBE = "/payment",
}

export const AuthIgnoredPaths = [ROUTES.FINISH_SIGNUP];
