import moment from "moment";
import { convert } from "html-to-text";
import { IApplicant, IProjectDetails } from "../RecruitersPanel/helper";

export const emailEditorRules = [
  { required: true, message: `Email body is required field` },
  () => ({
    validator(_: any, value: string) {
      if (value && convert(value).length > 3) return Promise.resolve();
      return Promise.reject(
        new Error("Email body must contain some valid text")
      );
    },
  }),
];

export const getSchduleMeetingBody = (
  date: string,
  hour: number,
  managerName: string,
  position: string
) => {
  const dateVal = moment(`${date} ${hour}`, "MM-DD h");
  return `<p>
      Your interview has been scheduled with 
      <b>${managerName}</b> 
      for the position of 
      <b>${position}</b> 
      on 
      <b>${dateVal.format("dddd, MMM DD, YYYY")}</b> 
      at 
      <b>${dateVal.format("hh:mm A")}</b> 
      (PST).
      </p>`;
};

export const getReminderEmailBody = (
  candidate: IApplicant,
  project?: IProjectDetails
) => {
  if (!project) return "";
  const { managers, name: companyName } = project;

  const { name, position, interviewLink, interviewDate, interviewType } =
    candidate;
  if (!interviewDate || interviewDate === "N/A") return "";

  const dateVal = moment(interviewDate);
  return `
    <p style="font-size:14px;margin-bottom:12px">
      <b>${name}'s </b>
      <span>interview has been scheduled with </span>
      <b>${managers?.[0].name} </b>
      <span>for the position of </span>
      <b>${position} </b>
      <span>for </span>
      <b>${companyName} </b>
      <span>on </span>
      <b>${dateVal.format("dddd, MMM DD, YYYY")} </b>
      <span>at </span>
      <b>${dateVal.format("hh:mm A")} </b>
      (PST).
    </p>
    ${
      interviewLink && interviewLink !== "N/A"
        ? `<p style="font-size:14px;margin-bottom:12px">
      You can click 
      <a href=${interviewLink}>here </a>
      <span>to join the call as well.</span>
    </p>`
        : `<p style="font-size:14px;margin-bottom:12px">
      It will be a <strong>${interviewType}</strong> meeting. 
    </p>`
    }
    <p style="font-size:14px;margin-bottom:12px">Regards: The Chronoflow.ai team</p>`;
};
