import { Row, Col } from "antd";
import React, { FC, useEffect, useState } from "react";
import { IApplicant, IProjectDetails } from "../helper";
import ApplicantsList from "./applicantsList";
import { axiosInstant, endpoints } from "../../../api";
import { showError } from "../../../Notifications";

interface Props {
  project?: IProjectDetails;
}

export const ApplicantsListPanel: FC<Props> = ({ project }) => {
  const [applicants, setApplicants] = useState<Array<IApplicant>>([]);

  const fetchApplicants = () => {
    if (project?._id) {
      axiosInstant
        .get(endpoints.getAllCandidates(project._id))
        .then((response) => {
          const { data } = response;
          if (data) setApplicants(data);
        })
        .catch((error) => showError(error));
    }
  };
  useEffect(fetchApplicants, [project]);

  const props = { applicants, projectId: project?._id };
  return (
    <>
      <Row>
        <Col span={24}>
          <ApplicantsList {...props} refetch={fetchApplicants} />
        </Col>
      </Row>
    </>
  );
};
export default ApplicantsListPanel;
