import { Layout } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const { Header } = Layout;

const PaymentPageHeader = () => (
  <Header className="header">
    <div className="logo">
      <Link to="/">
        <img
          src="./chronoflow.svg"
          alt="ChronoFlow Logo"
          className="logo-img"
        />
      </Link>
    </div>
  </Header>
);

export default PaymentPageHeader;
