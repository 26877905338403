import React, { useMemo } from "react";
import { Layout, Menu } from "antd";
import { PieChartOutlined, CalendarOutlined } from "@ant-design/icons";
import TopBar from "./Topbar";
import { Link, Outlet, useLocation } from "react-router-dom";
import { ROUTES, UserType } from "./common";
import { useUser } from "../context";

const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

const commonPaths = [
  { label: "Dashboard", value: ROUTES.DASHBOARD, icon: <PieChartOutlined /> },
];

const managerPaths = [
  { label: "Calendar", value: ROUTES.CALENDAR, icon: <CalendarOutlined /> },
];

export const SideLayout = () => {
  const { user } = useUser();
  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = React.useState(false);
  const onCollapse = (val: boolean) => setCollapsed(val);

  const sidebarPaths = useMemo(() => {
    const customPaths = user.category === UserType.MANAGER ? managerPaths : [];
    return [...commonPaths, ...customPaths];
  }, [user]);

  const selectedKey = useMemo(() => {
    const path = sidebarPaths.find((e) => e.value === pathname);
    if (path) return path.value;
    return "/dashboard";
  }, [sidebarPaths, pathname]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
        <div className="logo" />
        <Menu theme="dark" mode="inline" selectedKeys={[selectedKey]}>
          {sidebarPaths.map((path) => (
            <Menu.Item key={path.value} icon={path.icon}>
              <Link to={path.value}>{path.label}</Link>
            </Menu.Item>
          ))}
          {/* <SubMenu key="sub1" icon={<UserOutlined />} title="User">
            <Menu.Item key="3">Tom</Menu.Item>
            <Menu.Item key="4">Bill</Menu.Item>
            <Menu.Item key="5">Alex</Menu.Item>
          </SubMenu>
          <SubMenu key="sub2" icon={<TeamOutlined />} title="Team">
            <Menu.Item key="6">Team 1</Menu.Item>
            <Menu.Item key="7">Team 2</Menu.Item>
          </SubMenu>
          <Menu.Item key="8" icon={<FileOutlined />}>
            Files
          </Menu.Item> */}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <TopBar />
        </Header>
        <Content style={{ maxHeight: "calc(100vh - 125px)", overflow: "auto" }}>
          <Outlet />
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Dashboard ©2024 Created by ChronoFlow
        </Footer>
      </Layout>
    </Layout>
  );
};

export default SideLayout;
