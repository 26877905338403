import React from 'react';
import { Dropdown as Dropdown, Avatar} from 'antd';
import { UserOutlined } from '@ant-design/icons';

const CustomDropdown = ({ overlay }) => {
  return (
    <Dropdown overlay={overlay} placement="bottomRight">
        <Avatar size={40} icon={<UserOutlined />} style={{ cursor: 'pointer'}}/>
    </Dropdown>
  );
};



export default CustomDropdown;
