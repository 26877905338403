import { Table } from "antd";
import React, { FC, useMemo } from "react";
import { ApplicantsColumns, IApplicant, IProjectDetails } from "../helper";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { ROUTES } from "../../../Components/common";

interface Props {
  applicants: Array<IApplicant>;
  project?: IProjectDetails;
  onDelete: (_id: string) => void;
  onPdfUpload: (_id: string, pdfUlr: string) => void;
  openRemarks: (_id: string) => void;
  sendEmailRequest: (_id: string, emailBody: string) => void;
}

export const ApplicantsList: FC<Props> = ({
  applicants,
  onDelete,
  onPdfUpload,
  openRemarks,
  sendEmailRequest,
  project,
}) => {
  const navigate = useNavigate();

  const dataSource = useMemo(
    () => applicants.map((e) => ({ ...e, key: e._id })),
    [applicants]
  );

  const scheduleCall = (applicantId: string) => {
    const query = queryString.stringify({
      applicantId,
      projectId: project?._id,
    });
    navigate(`${ROUTES.AVAILABILITY}?${query}`);
  };

  return (
    <Table
      dataSource={dataSource}
      columns={ApplicantsColumns(
        onDelete,
        onPdfUpload,
        scheduleCall,
        sendEmailRequest,
        openRemarks,
        project
      )}
    />
  );
};

export default ApplicantsList;
