import React, { FC, useState } from "react";
import { Button, Spin } from "antd";

const OfficeLoginBtn: FC = () => {
  const [loading, setLoading] = useState(false);

  const signInUser = (pathName: string) => {
    const redirectTo = `${process.env.REACT_APP_BASE_URL}/${pathName}?path=finishSignup`;
    const url = new URL(redirectTo);
    // @ts-ignore
    window.location = url;
  };

  const handleOfficeAuth = () => {
    const path = process.env.REACT_APP_OFFICE_AUTH_ENDPOINT;
    setLoading(true);
    if (path) signInUser(path);
  };

  return (
    <Button
      type="default"
      size="large"
      className="auth-button"
      onClick={handleOfficeAuth}
      disabled={loading}
    >
      Sign in with Microsoft
      {loading && <Spin style={{ marginLeft: 10 }} />}
    </Button>
  );
};

export default OfficeLoginBtn;
