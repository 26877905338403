import React, { FC, useEffect, useRef, useState } from "react";
import { IApplicant, IProjectDetails } from "../helper";
import { Button, Form, FormInstance, FormProps, Modal } from "antd";
import EnrichEditor from "../../Editor";
import { emailEditorRules, getReminderEmailBody } from "../../common/emailBody";

interface Props {
  data: IApplicant;
  project?: IProjectDetails;
  sendEmail: (id: string, email: string) => void;
}

export const EmailRequestBtn: FC<Props> = ({ data, project, sendEmail }) => {
  const [form] = Form.useForm();
  const formRef = useRef<FormInstance<any>>(null);
  const { _id, interviewDate, isEmailSent } = data;

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (data && project) {
      const text = getReminderEmailBody(data, project);
      form.setFieldValue("emailBody", text);
    }
  }, [data, project, form]);

  if (interviewDate === "N/A") return <>N/A</>;
  if (isEmailSent) return <>Sent</>;

  const submitEmail: FormProps<{
    emailBody: string;
  }>["onFinish"] = async (values) => {
    sendEmail(_id, values.emailBody);
    setShowModal(false);
  };

  return (
    <>
      <Button type="primary" onClick={() => setShowModal(true)}>
        Send Email
      </Button>
      {showModal && (
        <Modal
          open
          onCancel={() => setShowModal(false)}
          onOk={() => formRef.current?.submit()}
          title="Edit Email Body"
          centered
          okText="Send Email"
        >
          <div style={{ margin: "20px 0" }}>
            <Form
              onFinish={submitEmail}
              ref={formRef}
              form={form}
              layout="vertical"
            >
              <Form.Item name="emailBody" rules={emailEditorRules}>
                <EnrichEditor placeholder="Type reminder email for the Candidate and the Hiring Manager" />
              </Form.Item>
            </Form>
            <div style={{ marginBottom: 10, fontStyle: "italic" }}>
              <strong style={{ color: "red" }}>Disclaimer: </strong>
              This email will be sent to the candidate and the hiring manager.
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default EmailRequestBtn;
