export const endpoints = {
  authenticate: `/auth/authenticate`,
  getMe: `/auth/getMe`,
  user: `/users`,
  updateUser: (id: string) => `/users/${id}`,

  contact: `/contact`,

  project: `/projects`,
  myProjects: `/projects/mine`,
  getProjectById: (id: string) => `/projects/${id}`,

  getAllCandidates: (id: string) => `/candidates/all/project/${id}`,
  candidate: `/candidates`,
  uploadCandidates: `/candidates/upload`,
  getSingleCandidate: (id: string) => `/candidates/${id}`,
  emailRequest: `/candidates/emailRequest`,

  myCalendar: `/calendar/mine`,
  getMyCalendar: (month: number) => `/calendar/mine/${month + 1}`,
  availabilityByProjectId: (id: string, month: number) =>
    `/calendar/availability/${id}?month=${month + 1}`,
  availableManagers: `/calendar/availableManagers`,
  scehduleIntervew: `/calendar/scehduleIntervew`,

  emailSubscription: "/subscriptions/email",

  getPreSignedUrl: `s3Upload/getUploadUrl`,

  createRemark: "/remarks/new",
  getRemarks: (id: string) => `/remarks/${id}`,
};
