import React, { useState, useMemo, useEffect } from "react";
import MonthDropdown from "./MonthDropdown";
import WorkingHoursSelector from "./WorkingHourSelector";
import DisplayModeSelector from "./DisplayModeSelector";
import "../../App.css";
import {
  DisplayMode,
  IAvailableSlot,
  IReservedSlot,
  defaultWorkingHours,
  getDatesArray,
  getHourBounds,
} from "./helper";
import AvailabilityTable from "./AvailablilityTable";
import { axiosInstant, endpoints } from "../../api";
import { useNavigate } from "react-router-dom";
import { ErrorType, ROUTES } from "../common";
import { Col, Row, Typography } from "antd";
import { showError, showSuccess } from "../../Notifications";

const Calendar = () => {
  const navigate = useNavigate();

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedHours, setSelectedHours] = useState(defaultWorkingHours);
  const [displayMode, setDisplayMode] = useState(DisplayMode.WORKINGDAY);
  const [reservedSlots, setReservedSlots] = useState<Array<IReservedSlot>>([]);
  const [defaultSlots, setDefaultSlots] = useState<Array<IAvailableSlot>>([]);
  const [loading, setLoading] = useState(true);
  const [connectionError, setConnectionError] = useState(false);

  const datesArray = useMemo(
    () => getDatesArray(selectedMonth, displayMode),
    [selectedMonth, displayMode]
  );

  const loadCalendar = async () => {
    try {
      const { data } = await axiosInstant.get(
        endpoints.getMyCalendar(selectedMonth)
      );
      if (data) {
        const { availableSlots = [], reservedSlots = [] } = data || [];
        if (availableSlots.length) {
          setDefaultSlots(availableSlots);
          setSelectedHours(getHourBounds(availableSlots));
        }
        setReservedSlots(reservedSlots);
      }
    } catch (err) {
      const error = err as ErrorType;
      if (error.response.status === 422) setConnectionError(true);
      else navigate(ROUTES.LANDING);
    }
    setLoading(false);
  };

  const onSlotUpdate = (slots: Array<IAvailableSlot>) => {
    axiosInstant
      .post(endpoints.myCalendar, { availableSlots: slots })
      .then(() => showSuccess("Calendar updated successfully"))
      .catch((err) => showError(err));
  };

  useEffect(() => {
    loadCalendar();
  }, [selectedMonth]);

  const centeredText = (text: string) => (
    <Row>
      <Col span={24}>
        <Typography.Title level={3} style={{ textAlign: "center" }}>
          {text}
        </Typography.Title>
      </Col>
    </Row>
  );

  if (!loading) {
    if (connectionError) {
      return centeredText(
        "You have not connected your calendar with chornoflow yet."
      );
    }

    return (
      <div className="calendar-container">
        <MonthDropdown {...{ selectedMonth, setSelectedMonth }} />
        <DisplayModeSelector {...{ displayMode, setDisplayMode }} />
        <WorkingHoursSelector {...{ selectedHours, setSelectedHours }} />
        <AvailabilityTable
          {...{
            datesArray,
            selectedHours,
            defaultSlots,
            reservedSlots,
            onUpdate: onSlotUpdate,
          }}
        />
      </div>
    );
  }
  return centeredText("Loading...");
};

export default Calendar;
