import { Table } from "antd";
import React, { FC, useMemo } from "react";
import { ApplicantsColumns, IApplicant } from "../helper";

interface Props {
  applicants: Array<IApplicant>;
  projectId?: string;
  refetch: () => void;
}

export const ApplicantsList: FC<Props> = ({ applicants, refetch }) => {
  const dataSource = useMemo(
    () => applicants.map((e) => ({ ...e, key: e._id })),
    [applicants]
  );

  return (
    <Table dataSource={dataSource} columns={ApplicantsColumns(refetch)} />
  );
};

export default ApplicantsList;
