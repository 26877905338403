import { Button, Form, FormInstance, FormProps, Modal, Select } from "antd";
import React, { FC, useRef, useState } from "react";
import FormInput from "../../FormInput";
import { CheckOutlined, PlusOutlined } from "@ant-design/icons";
import { IProjectDetails, priorityList } from "../helper";
import HiringManagerInput from "./hiringManagerInputs";
import { axiosInstant, endpoints } from "../../../api";
import { showError, showSuccess } from "../../../Notifications";

interface Props {
  onOkay: (form: IProjectDetails) => void;
  onCancel: () => void;
}

export const CreateNewProjectPanel: FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const { onOkay } = props;
  const formRef = useRef<FormInstance<any>>(null);

  const submitForm = () => {
    formRef.current?.submit();
  };

  const handleSubmit: FormProps<IProjectDetails>["onFinish"] = async (values) => {
    setIsLoading(true);
    try {
    const { data } = await axiosInstant.post(endpoints.project, values);
    showSuccess('Project Created Successful');
    onOkay(data);
    } catch (err) {
      showError(err);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      {...props}
      title="Create New Project"
      centered
      open
      loading={isLoading}
      onOk={submitForm}
    >
      <Form onFinish={handleSubmit} ref={formRef} style={{ marginTop: 20 }}>
        <FormInput label="Project Name" name="name" isRequired />
        <Form.Item
          name="priority"
          label="Select Priority"
          rules={[{ required: true, message: "Priority is required field" }]}
        >
          <Select
            optionLabelProp="label"
            placeholder="Select Priority"
            menuItemSelectedIcon={<CheckOutlined />}
          >
            {priorityList.map((priority) => (
              <Select.Option key={priority.value} {...priority}>
                {priority.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.List name="managers">
          {(fields, { add, ...rest }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <HiringManagerInput
                  {...rest}
                  field={{ ...field, noStyle: true }}
                  index={index}
                />
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: "60%" }}
                  icon={<PlusOutlined />}
                >
                  Add New Manager
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default CreateNewProjectPanel;
