// src/components/TopBar.js
import React from "react";
import { Menu, Breadcrumb, Avatar, Space, Typography } from "antd";
import { UserOutlined, LogoutOutlined, HomeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import CustomDropdown from "./Dropdown";
import { useUser } from "../context";
import { ROUTES } from "./common";

const TopBar = () => {
  const { user, setUser } = useUser();
  const navigate = useNavigate();

  const handleLogout = () => {
    setUser(undefined);
    localStorage.clear();
    navigate(ROUTES.LANDING); // Redirect to login page
  };

  const sliceText = (/** @type {string | any[]} */ text, /** @type {number} */ sliceTo) => {
    if (text.length < sliceTo) return text;
    return `${text.slice(0, sliceTo)}...`;
  };

  const menu = (
    <Menu>
      <Menu.Item key="profile">
        <Space>
          <Avatar
            size={40}
            icon={<UserOutlined />}
            style={{ cursor: "pointer" }}
          />
          <div>
            <Typography style={{ fontSize: 14 }}>
              {sliceText(user.name, 20)}
            </Typography>
            <Typography style={{ fontSize: 12 }}>
              {sliceText(user.email, 25)}
            </Typography>
          </div>
        </Space>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" onClick={handleLogout}>
        <LogoutOutlined /> Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      className="topbar"
      style={{
        backgroundColor: "#001529",
        height: "60px",
        display: "flex",
        alignItems: "center",
        padding: "0 20px",
        color: "white",
      }}
    >
      <Breadcrumb style={{ color: "white", lineHeight: "60px" }}>
        <Breadcrumb.Item>
          <a href="/" style={{ color: "white" }}>
            <HomeOutlined /> Home
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/calendar" style={{ color: "white" }}>
            Calendar
          </a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ flex: 1 }}></div> {/* This fills the remaining space */}
      <CustomDropdown overlay={menu} />
    </div>
  );
};

export default TopBar;
