import { Button, Form, FormInstance, FormProps, Modal, Space } from "antd";
import React, { FC, useRef } from "react";
import FormInput from "../FormInput";
import { LoginForm } from "./helper";
import { axiosInstant, endpoints } from "../../api";
import { ROUTES, TOKEN_KEY } from "../common";
import { useNavigate } from "react-router-dom";
import OfficeLoginBtn from "./OfficeLoginBtn";
import { showError, showNotification, showSuccess } from "../../Notifications";

interface Props {
  onCancel: () => void;
}

export const AuthFormModal: FC<Props> = (props) => {
  const navigate = useNavigate();
  const formRef = useRef<FormInstance<any>>(null);

  const submitForm = () => {
    formRef.current?.submit();
  };

  const handleSubmit: FormProps<LoginForm>["onFinish"] = async (values) => {
    try {
      const { data } = await axiosInstant.post(endpoints.authenticate, values);
      if (data?.authorization) {
        localStorage.setItem(TOKEN_KEY, data.authorization);
        showSuccess('Login Successful');
        navigate(ROUTES.DASHBOARD);
        props.onCancel();
      }
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal
      {...props}
      title="Login to Chronoflow"
      centered
      open
      onOk={submitForm}
    >
      <Form onFinish={handleSubmit} ref={formRef} style={{ marginTop: 40 }}>
        <FormInput label="Email" name="email" isRequired isEmail />
        <FormInput label="Password" name="password" isRequired isPassword />
      </Form>
      <Space
        direction="vertical"
        size="large"
        align="center"
        style={{ width: "100%", marginBottom: 40 }}
      >
        <div style={{ margin: 8 }}>OR</div>
        <OfficeLoginBtn />
      </Space>
      <div className="terms">
        <p className="terms">
          By proceeding, you agree to the <br />{" "}
          <a href="/terms">Terms of Service</a> and{" "}
          <a href="/privacy">Privacy Policy</a>.
        </p>
      </div>
    </Modal>
  );
};

export default AuthFormModal;
