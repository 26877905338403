import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./Components/Landingpage";
import SideLayout from "./Components/SideLayout";
import Calendar from "./Components/Calendar";
import DashboardPage from "./Components/Dashboard";
import AvailabilityCalendar from "./Components/Calendar/AvailabilityCalendar";
import TermsOfService from "./Components/AuthPanel/TermsOfService";
import PrivacyPolicy from "./Components/AuthPanel/PrivacyPolicy";
import FinishSignupPage from "./Components/FinishSignUp";
import { AuthProvider } from "./context";
import { ROUTES } from "./Components/common";
import "./App.css";
import PricingCards from "./Components/Payment";

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path={ROUTES.FINISH_SIGNUP} element={<FinishSignupPage />} />
          <Route path={ROUTES.LANDING} element={<LandingPage />} />
          <Route path={ROUTES.SUBSCRIBE} element={<PricingCards showHeader />} />

          <Route element={<SideLayout />}>
            <Route path={ROUTES.DASHBOARD} element={<DashboardPage />} />
            <Route path={ROUTES.CALENDAR} element={<Calendar />} />
            <Route
              path={ROUTES.AVAILABILITY}
              element={<AvailabilityCalendar />}
            />
          </Route>
        </Routes>
      </AuthProvider>

      <Routes>
        <Route path={ROUTES.TERMS} element={<TermsOfService />} />
        <Route path={ROUTES.POLICY} element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
};

export default App;
