import React, { useState } from "react";
import { Button, Form, FormProps } from "antd";
import { axiosInstant, endpoints } from "../../api";
import FormInput from "../FormInput";
import { showError } from "../../Notifications";

const SubscriptionForm = () => {
  const [loading, setLoading] = useState(false);

  const handleSubscribe: FormProps<{ email: string }>["onFinish"] = async (
    values
  ) => {
    setLoading(true);
    try {
      await axiosInstant.post(endpoints.emailSubscription, values);
    } catch (error) {
      showError(error);
    }
    setLoading(false);
  };

  return (
    <div className="subscribe-container">
      <Form onFinish={handleSubscribe} style={{ display: "flex" }}>
        <FormInput
          name="email"
          customInputProps={{
            placeholder: "Enter your email",
            size: "large",
            className: "subscribe-input",
          }}
          label="Email"
          isRequired
          isEmail
          hideLabel
        />
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="subscribe-button"
            disabled={loading}
          >
            {loading ? "Subscribing..." : "Subscribe"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SubscriptionForm;
