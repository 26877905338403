import React from 'react';

const TermsOfService = () => {
  return (
    <div>
      <h1>Terms of Service</h1>
      <p><strong>Effective Date:</strong> June 3, 2024</p>
      
      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing and using ChronoFlow ("Service"), you accept and agree to be bound by the terms and provisions of this agreement. If you do not agree to abide by these terms, please do not use this Service.
      </p>

      <h2>2. Description of Service</h2>
      <p>
        ChronoFlow is a B2B product that assists recruiters and hiring managers in coordinating and communicating efficiently through AI-powered features. Users can sign in using Microsoft Outlook or sign up through other provided methods.
      </p>

      <h2>3. Eligibility</h2>
      <p>
        This Service is intended for use by businesses and their designated employees. By using this Service, you confirm that you are:
      </p>
      <ul>
        <li>At least 18 years old.</li>
        <li>Authorized to act on behalf of your business entity.</li>
        <li>Capable of forming a binding contract with ChronoFlow.</li>
      </ul>

      <h2>4. Account Registration</h2>
      <p>
        To use the Service, you must create an account by providing accurate and complete information. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
      </p>

      <h2>5. Use of Service</h2>
      <p>
        You agree to use the Service only for lawful purposes and in accordance with these Terms. You are responsible for ensuring that your use of the Service does not violate any applicable laws or regulations.
      </p>
      <h3>5.1 Prohibited Uses</h3>
      <p>
        You agree not to:
      </p>
      <ul>
        <li>Use the Service for any unlawful purpose or in any way that could harm the Service or its users.</li>
        <li>Attempt to gain unauthorized access to any portion of the Service.</li>
        <li>Use the Service to transmit any material that is defamatory, offensive, or otherwise objectionable.</li>
        <li>Reverse engineer, decompile, or disassemble any portion of the Service.</li>
      </ul>

      <h2>6. Data Privacy and Security</h2>
      <p>
        Your use of the Service is also governed by our Privacy Policy, which outlines how we collect, use, and protect your information. By using the Service, you consent to the collection and use of your data in accordance with the Privacy Policy.
      </p>

      <h2>7. Intellectual Property</h2>
      <p>
        All content, trademarks, service marks, logos, and intellectual property rights related to the Service are owned by ChronoFlow or its licensors. You agree not to reproduce, duplicate, copy, sell, or exploit any portion of the Service without our express written permission.
      </p>

      <h2>8. Disclaimer of Warranties</h2>
      <p>
        The Service is provided on an "as is" and "as available" basis. ChronoFlow makes no warranties, express or implied, regarding the Service, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
      </p>

      <h2>9. Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by law, ChronoFlow shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from:
      </p>
      <ul>
        <li>Your use or inability to use the Service.</li>
        <li>Any unauthorized access to or use of our servers and/or any personal information stored therein.</li>
        <li>Any bugs, viruses, trojan horses, or the like that may be transmitted to or through our Service by any third party.</li>
      </ul>

      <h2>10. Indemnification</h2>
      <p>
        You agree to indemnify, defend, and hold harmless ChronoFlow and its affiliates, officers, directors, employees, and agents from and against any and all claims, damages, obligations, losses, liabilities, costs, and expenses arising from:
      </p>
      <ul>
        <li>Your use of the Service.</li>
        <li>Your violation of any term of these Terms of Service.</li>
        <li>Your violation of any third-party right, including without limitation any intellectual property, property, or privacy right.</li>
      </ul>

      <h2>11. Modifications to the Service and Terms</h2>
      <p>
        ChronoFlow reserves the right to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice. We also reserve the right to modify these Terms of Service at any time. Your continued use of the Service after any such changes constitutes your acceptance of the new Terms of Service.
      </p>

      <h2>12. Governing Law</h2>
      <p>

       These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of [Insert Jurisdiction].
     </p>

     <h2>13. Contact Information</h2>
      <p>

      If you have any questions about these Terms, please contact us at:

        ammara@chronoflow.ai     </p>


      {/* Add more content as needed */}
    </div>
  );
}

export default TermsOfService;
