import { TableProps } from "antd";
import { IApplicant, IOnProjectSelect, IProjectDetails } from "./interfaces";
import { FilePdfOutlined } from "@ant-design/icons";
import EditableStatus from "../applicantsPanel/editableStatus";
import { startCase } from "lodash";
import { StatusType } from "../../../Components/common";
import RemarksView from "../applicantsPanel/RemarksView";

export const toHHMMSS = (val = 0) => {
  const hours = Math.floor(val / 3600);
  const minutes = Math.floor((val % 3600) / 60);
  let output = "";
  if (hours) output += `${hours} hr `;
  if (minutes) output += `${minutes} mins `;
  return output;
};

export const ApplicantsColumns = (
  refetch: () => void
): TableProps<IApplicant>["columns"] => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Role",
    dataIndex: "position",
    key: "position",
  },
  {
    title: "Meeting type",
    dataIndex: "interviewType",
    key: "interviewType",
    align: "center",
    render: (_, { interviewType }) => interviewType?.split('(')[0] ?? "N/A",
  },
  {
    title: "Interview Date",
    dataIndex: "interviewDate",
    key: "interviewDate",
    align: "center",
    render: (_, { interviewDate }) =>
      interviewDate && interviewDate !== "N/A"
        ? new Date(interviewDate).toLocaleDateString()
        : interviewDate,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    align: "center",
    render: (_, { _id, status }) => {
      const props = { id: _id, status };
      return <EditableStatus {...props} onUpdate={refetch} />;
    },
  },
  {
    title: "Resume",
    dataIndex: "resume",
    key: "resume",
    align: "center",
    render: (_, { resume }) =>
      resume ? (
        <a href={resume} target="_blank" rel="noreferrer">
          <FilePdfOutlined style={{ fontSize: 20, color: "#002140" }} />
        </a>
      ) : (
        ""
      ),
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    align: "center",
    render: (_, { _id, ...rest }) => <RemarksView id={_id} {...rest} />,
  },
];

export const ProjectsColumns = (
  onSelect: IOnProjectSelect
): TableProps<IProjectDetails>["columns"] => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (_, { name, _id }) => (
      <b
        onClick={() => onSelect(_id)}
        style={{ cursor: "pointer", color: "#00e" }}
      >
        {name}
      </b>
    ),
  },
  {
    title: "Recruiter Name",
    dataIndex: "name",
    key: "name",
    render: (_, { createdBy }) => createdBy?.name,
  },
  {
    title: "Total Applicants",
    dataIndex: "applicantsCount",
    key: "applicantsCount",
    width: 200,
    render: (_, { applicantsCount }) => applicantsCount || 0,
  },
];

export const getStatusList = (statusTypeArr?: Array<string>) => {
  const elems = statusTypeArr || Object.values(StatusType);
  return elems.map((value) => ({
    label: startCase(value.toLowerCase()),
    value: value as StatusType,
  }));
};
