import { Popconfirm, Space, TableProps } from "antd";
import {
  IApplicant,
  IOnDelete,
  IOnPdfUpload,
  IOnProjectSelect,
  IProjectDetails,
  IScheduleCall,
} from "./interfaces";
import { EditablePriority } from "../projectsPanel";
import { DeleteOutlined, MessageOutlined } from "@ant-design/icons";
import ApplicantStatus from "../applicantsPanel/ApplicantStatus";
import ApplicantResume from "../applicantsPanel/applicantResume";
import EmailRequestBtn from "../applicantsPanel/emailRequest";

export const toHHMMSS = (val = 0) => {
  const hours = Math.floor(val / 3600);
  const minutes = Math.floor((val % 3600) / 60);
  let output = "";
  if (hours) output += `${hours} hr `;
  if (minutes) output += `${minutes} mins `;
  return output;
};

export const ApplicantsColumns = (
  onDelete: IOnDelete,
  onPdfUpload: IOnPdfUpload,
  scheduleCall: IScheduleCall,
  sendEmailRequest: IOnPdfUpload,
  openRemarks: IOnDelete,
  project?: IProjectDetails
): TableProps<IApplicant>["columns"] => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Role",
    dataIndex: "position",
    key: "position",
  },
  {
    title: "Scheduled Interview",
    dataIndex: "status",
    key: "status",
    align: "center",
    render: (_, { _id, status }) => (
      <ApplicantStatus {...{ _id, status }} scheduleCall={scheduleCall} />
    ),
  },
  {
    title: "Meeting type",
    dataIndex: "interviewType",
    key: "interviewType",
    align: "center",
    render: (_, { interviewType }) => interviewType?.split('(')[0] ?? "N/A",
  },
  {
    title: "Interview Date",
    dataIndex: "interviewDate",
    key: "interviewDate",
    align: "center",
    render: (_, { interviewDate }) =>
      interviewDate && interviewDate !== "N/A"
        ? new Date(interviewDate).toLocaleDateString()
        : interviewDate,
  },
  {
    title: "Reminder Email",
    dataIndex: "isEmailSent",
    key: "isEmailSent",
    align: "center",
    render: (_, data) => (
      <EmailRequestBtn
        project={project}
        data={data}
        sendEmail={sendEmailRequest}
      />
    ),
  },
  {
    title: "Resume",
    dataIndex: "resume",
    key: "resume",
    align: "center",
    className: "resume-upload-column",
    render: (_, { _id, resume }) => (
      <ApplicantResume {...{ _id, resume }} onUpload={onPdfUpload} />
    ),
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    align: "center",
    render: (_, { _id, remarks }) =>
      remarks ? (
        <MessageOutlined
          style={{ fontSize: 20, color: "#002140" }}
          onClick={() => openRemarks(_id)}
        />
      ) : (
        ""
      ),
  },
  {
    title: "Actions",
    dataIndex: "action",
    align: "center",
    render: (_, { _id }) => (
      <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(_id)}>
        <DeleteOutlined style={{ fontSize: 20, color: "#002140" }} />
      </Popconfirm>
    ),
  },
];

export const ProjectsColumns = (
  onSelect: IOnProjectSelect
): TableProps<IProjectDetails>["columns"] => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (_, { name, _id }) => (
      <b
        onClick={() => onSelect(_id)}
        style={{ cursor: "pointer", color: "#00e" }}
      >
        {name}
      </b>
    ),
  },
  {
    title: "Priority",
    dataIndex: "priority",
    key: "priority",
    width: 200,
    render: (_, { _id, priority }) => (
      <EditablePriority id={_id} initialValue={priority} />
    ),
  },
  {
    title: "Total Applicants",
    dataIndex: "applicantsCount",
    key: "applicantsCount",
    render: (_, { applicantsCount }) => applicantsCount || 0,
  },
  {
    title: "Completion",
    dataIndex: "completionPercentage",
    key: "completionPercentage",
    render: (_, { completionPercentage }) => `${completionPercentage}%`,
  },
  {
    title: "Hiring Managers",
    dataIndex: "managers",
    key: "managers",
    render: (_, { managers }) => (
      <Space size="middle">{managers?.map((e) => e.name)}</Space>
    ),
  },
];
