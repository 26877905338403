import React, { FC, useState } from "react";
import { Row } from "antd";
import PaymentPageHeader from "./Header";
import { IPlan, plans } from "./constants";
import Popup from "../popup";
import PlanCard from "./PlanCard";
import PaymentForm from "./PaymentForm";
import "../../App.css"; // Assuming you have some additional styles here

interface Props {
  showHeader?: boolean;
}

export const PricingCards: FC<Props> = ({ showHeader }) => {
  const [showContactForm, setShowContactForm] = useState(false);
  const [showStripeForm, setShowStripeForm] = useState(false);
  const [amount, setAmount] = useState(0);

  const handBtnClick = (selectedPlan: IPlan) => {
    const { btn, salePrice, price } = selectedPlan;
    if (btn === "Contact Us") setShowContactForm(true);
    else if (btn === "Subscribe") {
      const priceInNumber = parseInt((salePrice || price || "0").toString());
      setAmount(priceInNumber);
      setShowStripeForm(true);
    }
  };

  return (
    <div>
      {showHeader && <PaymentPageHeader />}
      <div id="pricing-cards"> {/* Add id attribute */}
      <div className="pricing-section">
        <div className="container">
          <h2 className="section-title">Our Pricing Plans</h2>
          <Row gutter={[16, 16]} justify="center">
            {plans.map((plan, index) => (
              <PlanCard plan={plan} key={index} onClick={handBtnClick} />
            ))}
          </Row>
        </div>
      </div>
      </div>
      <Popup
        visible={showContactForm}
        onClose={() => setShowContactForm(false)}
      />
      <PaymentForm
        amount={amount}
        open={showStripeForm}
        onCancel={() => setShowStripeForm(false)}
      />
    </div>
  );
};

export default PricingCards;
