import { Space, Button } from "antd";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

export const BackBtn: FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <Button
      type="default"
      style={{
        width: "fit-content",
        marginTop: 10,
      }}
      onClick={handleClick}
    >
      <Space>
        <ArrowLeftOutlined />
        Back
      </Space>
    </Button>
  );
};

export default BackBtn;
