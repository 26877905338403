import { Row, Col, Flex } from "antd";
import React, { useEffect, useState } from "react";
import { IProjectDetails, ProjectsMainWrapper } from "./helper";
import { ProjectListPanel } from "./projectsPanel";
import { ApplicantsListPanel } from "./applicantsPanel";
import { useSearchParams } from "react-router-dom";
import PageHeading from "./pageHeading";
import { axiosInstant, endpoints } from "../../api";
import { showError } from "../../Notifications";
import BackBtn from "../backBtn";

export const RecruitersPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [projectList, setProjectList] = useState<Array<IProjectDetails>>([]);
  const [selectedProject, setSelectedProject] = useState<IProjectDetails>();

  const createNewProject = (projectField: IProjectDetails) => {
    setProjectList([...projectList, projectField]);
  };

  useEffect(() => {
    try {
      axiosInstant
        .get(endpoints.myProjects)
        .then((e) => setProjectList(e.data))
        .catch((err) => showError(err));
    } catch (err) {
      showError(err);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!projectList.length) return;
    const projectParam = searchParams.get("project") || -1;
    const project = projectList.find((e) => e._id === projectParam);
    if (project) setSelectedProject(project);
    else {
      setSelectedProject(undefined);
      setSearchParams((params) => {
        params.delete("project");
        return params;
      });
    }
  }, [projectList, searchParams, setSearchParams]);

  return (
    <Row>
      <Col span={24}>
        <Flex vertical style={ProjectsMainWrapper}>
          {selectedProject && <BackBtn />}
          <PageHeading project={selectedProject} />
          {selectedProject ? (
            <ApplicantsListPanel project={selectedProject} />
          ) : (
            <ProjectListPanel
              list={projectList}
              onCreateNewProject={createNewProject}
            />
          )}
        </Flex>
      </Col>
    </Row>
  );
};

export default RecruitersPage;
