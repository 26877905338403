import { Typography } from "antd";
import React, { FC } from "react";
import { IProjectDetails } from "./helper";

interface Props {
  project?: IProjectDetails;
}
export const PageHeading: FC<Props> = ({ project }) => (
  <Typography.Title level={2}>{project?.name || "Projects"}</Typography.Title>
);

export default PageHeading;
