import React from "react";
import { Modal, Form, Input } from "antd";
import FormInput from "./FormInput";
import { axiosInstant, endpoints } from "../api";
import { showError, showSuccess } from "../Notifications";

const Popup = ({ visible, onClose }) => {
  const [form] = Form.useForm();

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      console.log("Form values:", values);
      try {
        await axiosInstant.post(endpoints.contact, values);
        showSuccess(
          "Your contact request with chronoflow platform is being sent"
        );
      } catch (err) {
        showError("Your contact request is failed. Please try again");
      }
      form.resetFields();
      onClose();
    } catch (err) {}
  };

  return (
    <Modal
      title="Query"
      open={visible}
      onCancel={onClose}
      onOk={handleOk}
      okText="Submit"
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <Form form={form} layout="vertical" clearOnDestroy preserve={false}>
        <FormInput label="Name" isRequired />
        <FormInput label="Company" isRequired />
        <FormInput label="Email" isRequired isEmail />
        <Form.Item
          name="message"
          label="Message"
          rules={[{ required: true, message: "Please enter your message" }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Popup;
