import { Modal, Typography, Space, Form, Input } from "antd";
import React, { FC, useEffect, useRef, useState } from "react";
import moment from "moment";
import { useUser } from "../../context";
import { axiosInstant, endpoints } from "../../api";
import { showError } from "../../Notifications";
import { IRemark } from "../RecruitersPanel/helper";

interface Props {
  candidateId: string;
  onCancel: () => void;
}

export const RemarksPanel: FC<Props> = ({ candidateId, onCancel }) => {
  const { user } = useUser();
  const [form] = Form.useForm();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [remarksList, setRemarksList] = useState<Array<IRemark>>([]);

  const scrollToEnd = () => {
    scrollRef.current?.scrollTo({
      left: 0,
      top: scrollRef.current.scrollHeight || 0,
      behavior: "smooth",
    });
  };

  const getuserName = (name: string) => (user.name === name ? "You" : name);

  const getCandidateRemarks = () => {
    axiosInstant(endpoints.getRemarks(candidateId)).then((e) => {
      setRemarksList(e?.data?.remarks || []);
    });
  };

  const closeModal = () => {
    setLoading(false);
    onCancel();
  };

  const createRemark = async () => {
    setLoading(true);
    const { remark: text } = await form.validateFields();
    try {
      await axiosInstant.post(endpoints.createRemark, {
        candidateId,
        text,
      });
      form.resetFields();
      getCandidateRemarks();
    } catch (e) {
      showError((e as Error)?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (candidateId) getCandidateRemarks();
  }, [candidateId]);

  useEffect(() => {
    setTimeout(scrollToEnd, 300);
  }, [remarksList]);

  return (
    <Modal
      title="Applicant Remarks"
      centered
      destroyOnClose
      open={!!candidateId}
      confirmLoading={loading}
      okText="Create Comment"
      onOk={createRemark}
      cancelText="Close"
      onCancel={closeModal}
    >
      <div style={{ maxHeight: 600, overflow: "auto" }} ref={scrollRef}>
        {remarksList.map(({ createdAt, createdBy, text }) => {
          return (
            <div
              style={{
                padding: "20px 10px",
                borderBottom: "1px solid #f4f4f4",
              }}
              key={createdAt}
            >
              <Space size="middle" style={{ marginBottom: 10 }}>
                <Typography style={{ fontSize: 16, fontWeight: "bold" }}>
                  {getuserName(createdBy)}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {moment(createdAt).format("DD MMM, hh:mm A")}
                </Typography>
              </Space>
              <Typography>{text}</Typography>
            </div>
          );
        })}
      </div>

      <div style={{ position: "relative", minHeight: 120 }}>
        <Form
          form={form}
          layout="vertical"
          style={{ position: "absolute", bottom: 0, width: "100%" }}
          preserve={false}
        >
          <Form.Item style={{ margin: "15px 0" }} name="remark">
            <Input.TextArea
              rows={3}
              placeholder="Comment (if any)"
              style={{ resize: "none" }}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
export default RemarksPanel;
