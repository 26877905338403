import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Layout, Card, Avatar } from "antd";
import {
  UserOutlined,
  TeamOutlined,
  Loading3QuartersOutlined,
} from "@ant-design/icons";
import "../App.css";
import { ROUTES, TOKEN_KEY, UserType } from "./common";
import { axiosInstant, endpoints } from "../api";
import { useUser } from "../context";
import { showError } from "../Notifications";

const { Header, Content } = Layout;

const categories = [
  {
    bgColor: "#87d068",
    icon: <UserOutlined />,
    name: "Recruiter",
    value: UserType.RECRUITER,
  },
  {
    bgColor: "#1890ff",
    icon: <TeamOutlined />,
    name: "Hiring Manager",
    value: UserType.MANAGER,
  },
];

const FinishSignUp = () => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const [searchParams] = useSearchParams();
  const [userId, setUserId] = useState("");

  function paramsToObject(entries: any) {
    const result = {};
    // @ts-ignore
    for (const [key, value] of entries) result[key] = value;
    return result;
  }

  useEffect(() => {
    const userObj = paramsToObject(searchParams.entries()) as any;
    if (userObj.token) {
      setUser(userObj);
      localStorage.setItem(TOKEN_KEY, userObj.token);
    }

    if (searchParams.get("category")) navigate(ROUTES.DASHBOARD);
    const id = searchParams.get("_id");
    if (id) setUserId(id);
  }, [navigate, searchParams]);

  const handleClick = async (e: UserType) => {
    if (userId) {
      try {
        await axiosInstant.put(endpoints.updateUser(userId), { category: e });
        setUser({ ...user, category: e });
        navigate(ROUTES.DASHBOARD);
      } catch (err) {
        showError(err);
      }
    }
  };

  if (!userId) <Loading3QuartersOutlined />;
  return (
    <Layout>
      <Header className="header">
        <div className="logo">
          <Link to="/">
            <img
              src="./chronoflow.svg"
              alt="ChronoFlow Logo"
              className="logo-img"
            />
          </Link>
        </div>
      </Header>
      <Content className="content">
        <div className="profile-cards">
          {categories.map((e) => (
            <Card
              className="profile-card"
              hoverable
              onClick={() => handleClick(e.value)}
            >
              <Avatar
                size={128}
                style={{ backgroundColor: e.bgColor }}
                icon={e.icon}
              />
              <h3>{e.name}</h3>
            </Card>
          ))}
        </div>
      </Content>
    </Layout>
  );
};

export default FinishSignUp;
