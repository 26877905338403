import React, { FC } from "react";
import { Button, Select, Form, Typography, FormProps } from "antd";
import FormInput from "../FormInput";
import FormPhoneInput from "../FormPhoneInput";
import { SignUpForm, formItemLayout, tailFormItemLayout } from "./helper";
import { CloseOutlined } from "@ant-design/icons";
import { axiosInstant, endpoints } from "../../api";
import { UserType } from "../common";
import { showError, showSuccess } from "../../Notifications";

interface Props {
  onClose: () => void;
  visible: boolean;
}

const ManualSignUpPanel: FC<Props> = ({ onClose, visible }) => {
  const handleSubmit: FormProps<SignUpForm>["onFinish"] = async (values) => {
    const payload = values;

    const phone = values.phone as any;
    if (phone)
      values.phone = `${phone.countryCode}${phone.areaCode}${phone.phoneNumber}`;

    try {
      await axiosInstant.post(endpoints.user, payload);
      showSuccess(
        "Your Sign up request is successful. Kindly go to the Dashboard and login with your provided credentials"
      );
    } catch (err) {
      showError(err);
    }
  };

  if (!visible) return null;

  return (
    <div className="hero-left">
      <Typography.Title level={2} unselectable="on" underline>
        Sign Up
      </Typography.Title>
      <div className="close-right-pane" onClick={onClose}>
        <CloseOutlined />
      </div>
      <div style={{ margin: 10 }}>
        <Form {...formItemLayout} onFinish={handleSubmit}>
          <FormInput name="name" label="Full Name" isRequired />
          <FormInput label="Email" isEmail isRequired />
          <FormInput label="Password" isPassword isRequired />
          <FormPhoneInput name="phone" label="Phone Number" />
          <FormInput label="Company" />
          <FormInput name="jobTitle" label="Job Title" />
          <Form.Item
            name="category"
            label="Category"
            rules={[{ required: true, message: "Category is required" }]}
          >
            <Select placeholder="Select Category">
              <Select.Option value={UserType.RECRUITER}>
                Recruiter
              </Select.Option>
              <Select.Option value={UserType.MANAGER}>
                Hiring Manager
              </Select.Option>
              <Select.Option value="candidate">Candidate</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" size="middle" htmlType="submit">
              Sign Up
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ManualSignUpPanel;
