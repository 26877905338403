import React from 'react';
import { List } from 'antd';
import { CheckCircleOutlined, StarOutlined, HeartOutlined } from '@ant-design/icons';

const BulletPoints = () => {
  const data = [
    'Reduces Recruitment Time by Automating the manual work',
    'Candidate tracking is Client-Centric rather than position-centric',
    'Takes Less than a Minute to Schedule an Interview',
    'Live Coordination with Hiring Managers for quick decision making', 
    'Automatic Staging of Candidates'
  ];

  const renderItem = (item) => {
    const boldWords = ['Reduces', 'Client-Centric', 'Minute', 'Live', 'Automatic'];
    const parts = item.split(' ');

    return (
      <span>
        {parts.map((part, i) => (
          <span key={i} style={{ fontWeight: boldWords.includes(part) ? 'bold' : 'normal' }}>
            {part}{' '}
          </span>
        ))}
      </span>
    );
  };

  const getIcon = (index) => {
    switch (index) {
      case 0: return <CheckCircleOutlined style={{ color: 'green', marginRight: 8, verticalAlign: 'middle' }} />;
      case 1: return <StarOutlined style={{ color: 'blue', marginRight: 8, display: 'inline-block', lineHeight: '1.5', verticalAlign: 'middle' }} />;
      case 2: return <HeartOutlined style={{ color: 'red', marginRight: 8, verticalAlign: 'middle' }} />;
      case 3: return <CheckCircleOutlined style={{ color: 'orange', marginRight: 8, verticalAlign: 'middle' }} />;
      case 4: return <StarOutlined style={{ color: 'purple', marginRight: 8, verticalAlign: 'middle' }} />;
      default: return <CheckCircleOutlined style={{ color: 'green', marginRight: 8, verticalAlign: 'middle' }} />;
    }
  };      

  return (
    <div className="bullet-points-container">
      <h2>Key Features:</h2>
      <List
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item style={{ fontSize: '18px', textAlign: 'left', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        
            {getIcon(index)}
            {renderItem(item)}
          </List.Item>
        )}
      />
    </div>
  );
};

export default BulletPoints;
