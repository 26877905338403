import React, { FC, useEffect, useRef, useState } from "react";
import { IInterviewDate } from "./helper";
import { FormInstance, Modal, Space } from "antd";
import { axiosInstant, endpoints } from "../../api";
import { IApplicant, IManager } from "../ManagersPanel/helper";
import { useSearchParams } from "react-router-dom";
import { useUser } from "../../context";
import { showError } from "../../Notifications";
import ScheduleMeetingForm from "./ScheduleMeetingForm";

interface Props {
  candidate: IApplicant;
  interviewDate: IInterviewDate;
  setInterviewDate: (e?: IInterviewDate) => void;
}

const ScheduleMeetingModal: FC<Props> = (props) => {
  const {
    interviewDate: { date, hour },
    setInterviewDate,
    candidate,
  } = props;

  const { user } = useUser();
  const formRef = useRef<FormInstance<any>>(null);
  const closeModal = () => setInterviewDate(undefined);
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [managers, setManaers] = useState<Array<IManager>>([]);
  const [selectedManager, setSelectedManager] = useState<IManager>();

  useEffect(() => {
    const projectId = searchParams.get("projectId");
    if (projectId) {
      axiosInstant
        .post(endpoints.availableManagers, { projectId, date, hour })
        .then((response) => {
          const { data } = response;
          setManaers(data);
          if (data.length) setSelectedManager(data[0]);
          setLoading(false);
        })
        .catch((err) => showError(err));
    }
  }, [date, hour, searchParams]);

  const getHeading = (heading: string, data: IManager) => (
    <Space>
      <div style={{ fontSize: 14, fontWeight: "600" }}>{heading}:</div>
      <div style={{ fontSize: 14 }}>{data.name}</div>
      <span>
        (
        <a href={`mailto://${data.email}`} style={{ fontSize: 12 }}>
          {data.email}
        </a>
        )
      </span>
    </Space>
  );

  const formProps = { ...props, loading, setLoading, formRef, selectedManager };
  return (
    <Modal
      title="Schedule Interview"
      centered
      open
      onOk={() => formRef.current?.submit()}
      onCancel={closeModal}
      confirmLoading={loading}
    >
      <div style={{ marginTop: 20 }}>
        {managers.length === 1 && getHeading("Interviewer", managers[0])}
      </div>
      <div>{getHeading("Candidate", candidate)}</div>
      <div>{getHeading("Hosted By", user)}</div>
      <ScheduleMeetingForm {...formProps} />
    </Modal>
  );
};

export default ScheduleMeetingModal;
