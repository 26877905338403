import { Row, Col, Button, Flex } from "antd";
import React, { FC, useState } from "react";
import { IProjectDetails } from "../helper";
import ProjectList from "./projectList";
import NoProjectsPage from "./noProjects";
import CreateNewProjectPanel from "./createProjectPanel";

interface Props {
  list: Array<IProjectDetails>;
  onCreateNewProject: (e: IProjectDetails) => void;
}
export const ProjectListPanel: FC<Props> = ({
  onCreateNewProject,
  ...rest
}) => {
  const { list } = rest;
  const [openNewProjectPanel, setNewProjectPanelVisibility] = useState(false);
  const showNewProjectPanel = () => setNewProjectPanelVisibility(true);
  const hideNewProjectPanel = () => setNewProjectPanelVisibility(false);

  const createProject = (projectDetails: IProjectDetails) => {
    onCreateNewProject(projectDetails);
    hideNewProjectPanel();
  };

  return (
    <>
      {list.length ? (
        <Row>
          <Col span={24} style={{ padding: "10px 20px" }}>
            <Flex justify="end">
              <Button size="large" type="primary" onClick={showNewProjectPanel}>
                Create Project
              </Button>
            </Flex>
          </Col>
          <Col span={24}>
            <ProjectList {...rest} />
          </Col>
        </Row>
      ) : (
        <NoProjectsPage onClick={showNewProjectPanel} />
      )}

      {openNewProjectPanel && (
        <CreateNewProjectPanel
          onOkay={createProject}
          onCancel={hideNewProjectPanel}
        />
      )}
    </>
  );
};
export default ProjectListPanel;
