import { Button, Upload, UploadProps, message } from "antd";
import React, { FC } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { axiosInstant, endpoints } from "../../../api";
import { RcFile } from "antd/es/upload";
import axios from "axios";
import moment from "moment";

interface Props {
  isNarrowed?: boolean;
  onUpload: (e: string) => void;
}

export const ResumeUpload: FC<Props> = ({ isNarrowed, onUpload }) => {
  let action = "";

  const uploadFile = async (payload: any) => {
    const { onSuccess, onError, file } = payload;
    const { type } = file;

    const headers = { "Content-Type": type, "Content-Disposition": "inline" };
    try {
      await axios.put(action, file, { headers });
      onSuccess(file);
      onUpload(action.split("?")[0]);
    } catch (err: any) {
      console.log(err?.message);
      onError({ event: err });
    }
  };

  const getSignedUrl = async (file: RcFile) => {
    const { name, type } = file;
    const keyName = `${moment().valueOf()}_${name}`;
    try {
      const { data } = await axiosInstant.post(endpoints.getPreSignedUrl, {
        keyName,
        contentType: type,
      });
      action = data;
      return data;
    } catch (err) {
      return false;
    }
  };

  const props: UploadProps = {
    name: "file",
    accept: ".pdf",
    maxCount: 1,
    action,
    beforeUpload: getSignedUrl,
    customRequest: uploadFile,
    onChange(info) {
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (!info.file.status || info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Upload {...props}>
      {isNarrowed ? (
        <Button
          icon={<UploadOutlined style={{ fontSize: 20, color: "#002140" }} />}
          type="link"
        />
      ) : (
        <Button icon={<UploadOutlined />}>Upload Resume</Button>
      )}
    </Upload>
  );
};

export default ResumeUpload;
