import React from 'react';
import '../App.css';

const VideoSection = ({ videoId }) => {
  const videoUrl = `https://www.youtube.com/embed/aVmh1rvaJIY`;

  return (
    <section className="video-section">
      <h2>Recruiter's Dashboard Demo</h2>
      <div className="video-container">
        <iframe
          width="640"
          height="360"
          src={videoUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded YouTube video"
        ></iframe>
      </div>
    </section>
  );
};

export default VideoSection;
