// CalendlyPopup.js
import React from 'react';
import { InlineWidget } from 'react-calendly';

const CalendlyPopup = ({ isVisible, onClose }) => {
  return (
    <div style={{ display: isVisible ? 'block' : 'none', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
      <div style={{ backgroundColor: '#fff', padding: 20, borderRadius: 10, position: 'relative' }}>
        <button onClick={onClose} style={{ position: 'absolute', top: 10, right: 10 }}>Close</button>
        <InlineWidget url="https://calendly.com/ammara-chronoflow/30mins" />
      </div>
    </div>
  );
};

export default CalendlyPopup;
