import React, { FC } from "react";
import "../../App.css";
import { AllMonths } from "./helper";

interface Props {
  selectedMonth: number;
  setSelectedMonth: (e: number) => void;
}
const MonthDropdown: FC<Props> = ({ selectedMonth, setSelectedMonth }) => (
  <select
    value={selectedMonth}
    onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
  >
    {AllMonths.map((month, index) => (
      <option key={index} value={index}>
        {month}
      </option>
    ))}
  </select>
);

export default MonthDropdown;
