import React, { FC } from "react";
import { Button } from "antd";
import { SignUpTypes } from "./helper";

interface Props {
  onSelect: (type: SignUpTypes) => void;
}

const AuthMainPanel: FC<Props> = ({ onSelect }) => {
  return (
            <div className="button-container">


      <Button
        type="primary"
        className="sign-up"
        onClick={() => onSelect(SignUpTypes.MANUAL)}
      >
        Get Started
      </Button>
      </div>

    
  
  );
  }
export default AuthMainPanel;
