import { Typography, Select } from "antd";
import React, { FC, useState } from "react";
import { IPriority, priorityList } from "../helper";
import { CheckOutlined } from "@ant-design/icons";
import { axiosInstant, endpoints } from "../../../api";
import { showSuccess } from "../../../Notifications";

interface Props {
  id?: string;
  initialValue: number;
}

export const EditablePriority: FC<Props> = ({ id, initialValue }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [priority, setPriority] = useState(
    priorityList.find((e) => e.value === initialValue) || priorityList[0]
  );

  const handleChange = (val: IPriority) => {
    if (id) {
      axiosInstant.patch(endpoints.getProjectById(id), { priority: val.value });
      showSuccess('Project Priority Updated Successful');
    }
    setIsEditable(false);
    setPriority(val);
  };

  if (!isEditable)
    return (
      <Typography.Text onClick={() => setIsEditable(true)}>
        {priority?.label}
      </Typography.Text>
    );
  return (
    <Select
      style={{ width: "100%" }}
      optionLabelProp="label"
      placeholder="Select Priority"
      menuItemSelectedIcon={<CheckOutlined />}
      value={priority}
      labelInValue
      onChange={handleChange}
    >
      {priorityList.map((priority) => (
        <Select.Option key={priority.value} {...priority}>
          {priority.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default EditablePriority;
