import React, { FC } from "react";
import { Button } from "antd";

interface Props {
  onClick?: () => void;
}

const UpdateButton: FC<Props> = (props) => {
  return (
    <div
      style={{
        marginTop: 20,
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Button type="default" {...props}>
        Update Availability
      </Button>
    </div>
  );
};

export default UpdateButton;
