import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { FC, FormEvent } from "react";
import { showError } from "../../Notifications";
import { axiosInstant, endpoints } from "../../api";
import { useUser } from "../../context";
import { Subscription } from "../common";
import moment from "moment";

interface Props {
  submitBtnRef: any;
}
const CheckoutForm: FC<Props> = ({ submitBtnRef }) => {
  const { user } = useUser();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    try {
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {},
        redirect: "if_required",
      });

      if (result.error) showError(result.error.message);
      else {
        const subscription = {
          name: Subscription.STANDERD,
          subscribedAt: moment().valueOf(),
          expiredAt: moment().add(1, "M").valueOf(),
        };
        await axiosInstant.put(endpoints.updateUser(user._id), {
          subscription,
        });
        window.location.href = '/';
      }
    } catch (err) {
      showError((err as Error).message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button type="submit" ref={submitBtnRef} style={{ display: "none" }}>
        Submit
      </button>
    </form>
  );
};

export default CheckoutForm;
