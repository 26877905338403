import axios from "axios";
import { TOKEN_KEY } from "../Components/common";

export const axiosInstant = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL as string,
});

// @ts-ignore
axiosInstant.interceptors.request.use((axiosConfig) => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (!token) return { ...axiosConfig };
  return {
    ...axiosConfig,
    headers: {
      authorization: localStorage.getItem(TOKEN_KEY),
    },
  };
});

const logoutUser = () => {
  localStorage.clear();
  window.location.pathname = "/";
};

axiosInstant.interceptors.response.use(
  (response) => {
    if (response.status === 401) logoutUser();
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) logoutUser();
    return Promise.reject(error);
  }
);
