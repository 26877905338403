import { Form, FormListFieldData, Input } from "antd";
import React, { FC } from "react";
import { MinusCircleOutlined } from "@ant-design/icons";

interface Props {
  field: FormListFieldData & { noStyle: boolean };
  index: number;
  remove: (index: number) => void;
}

export const HiringManagerInput: FC<Props> = ({ field, index, remove }) => {
  const { key, name, ...rest } = field;

  return (
    <Form.Item
      label={index === 0 ? "Hiring Managers" : ""}
      style={{ gap: 100, marginLeft: index ? 120 : 0 }}
      key={index}
    >
      <div style={{ display: "flex", gap: 10 }}>
        <Form.Item
          {...rest}
          name={[name, "name"]}
          rules={[{ required: true, message: "Name is required." }]}
        >
          <Input placeholder="Manager Name" style={{ width: "40%" }} />
        </Form.Item>
        <Form.Item
          {...rest}
          name={[name, "email"]}
          rules={[
            { required: true, message: "Email is required." },
            {
              type: "email" as any,
              message: "Please enter a valid email",
            },
          ]}
        >
          <Input placeholder="Manager Email" style={{ width: "50%" }} />
        </Form.Item>
        <MinusCircleOutlined
          className="dynamic-delete-button"
          onClick={() => remove(name)}
        />
      </div>
    </Form.Item>
  );
};

export default HiringManagerInput;
