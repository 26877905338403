import React, { ChangeEvent, FC } from "react";
import { IWorkingHours, getFormattedHours } from "./helper";

interface Props {
  selectedHours: IWorkingHours;
  setSelectedHours: (val: IWorkingHours) => void;
}

const WorkingHoursSelector: FC<Props> = ({
  selectedHours,
  setSelectedHours: onChange,
}) => {
  const handleStartHourChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const startTime = parseInt(e.target.value);
    const endTime = Math.max(startTime, selectedHours.endTime);
    onChange({ startTime, endTime });
  };

  const handleEndHourChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const endTime = parseInt(e.target.value);
    const startTime = Math.min(endTime, selectedHours.startTime);
    onChange({ startTime, endTime });
  };

  const getTimeRange = (limit = 0) =>
    Array.from({ length: 24 - limit }, (_, i) => i + limit).map((hour) => (
      <option key={hour} value={hour}>
        {getFormattedHours(hour)}
      </option>
    ));

  return (
    <div className="working-hours-selector">
      <div>
        <label>Start Hour:</label>
        <select
          value={selectedHours.startTime}
          onChange={handleStartHourChange}
        >
          {getTimeRange()}
        </select>
      </div>
      <div>
        <label>End Hour:</label>
        <select value={selectedHours.endTime} onChange={handleEndHourChange}>
          {getTimeRange(selectedHours.startTime)}
        </select>
      </div>
    </div>
  );
};

export default WorkingHoursSelector;
