import React, { FC, useEffect, useState } from "react";
import { CommentOutlined } from "@ant-design/icons";
import { IRemark } from "../../RecruitersPanel/helper";
import RemarksPanel from "../../RemarksPanel";
import { StatusType } from "../../common";

interface Props {
  id: string;
  remarks?: Array<IRemark>;
  status?: StatusType;
}

export const RemarksView: FC<Props> = ({ id, remarks, status }) => {
  const [showRemarks, setShowRemarks] = useState(false);
  const [prevStatus] = useState(status);

  useEffect(() => {
    if (status !== prevStatus && status === StatusType.REJECTED) setShowRemarks(true);
  }, [status]);

  return (
    <>
      <CommentOutlined
        style={{ fontSize: 20, color: "#002140" }}
        onClick={() => setShowRemarks(true)}
      />
      {showRemarks && (
        <RemarksPanel
          candidateId={id}
          onCancel={() => setShowRemarks(false)}
        />
      )}
    </>
  );
};

export default RemarksView;
