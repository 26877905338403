export enum SignUpTypes {
  GOOGLE = "google",
  LINKEDIN = "linkedin",
  MANUAL = "manual",
}

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export interface SignUpForm {
  name: string;
  email: string;
  password: string;
  phone: string;
  company?: string;
  jobTitle?: string;
  role: string;
}

export interface LoginForm {
  email: string;
  password: string;
}
